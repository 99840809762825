import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCheckbox,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../components/AuthContext';

interface Course {
    _id: string,
    name: string
  }
  
  interface MyCourse {
    _id: string,
    isChecked: boolean
  }

  interface Slot {
    _id: string,
    name: string,
  }
  
  interface MySlots {
    mon: Array<string>,
    tue: Array<string>,
    wed: Array<string>,
    thu: Array<string>,
    fri: Array<string>,
    sat: Array<string>,
    sun: Array<string>,
  }
  
const AddStudent: React.FC = () => {
    const {authInfo} = useAuth()!;
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
   
    const [email, setEmail] = useState < string > ("");
    const [newPass, setNewPass] = useState < string > ("");
  const [confirmPass, setConfirmPass] = useState < string > ("");
  const [name, setName] = useState < string > ("");
  const [phone, setPhone] = useState < string > ("");
  const [gender, setGender] = useState < string > ("");
  const [city, setCity] = useState < string > ("");
  const [title, setTitle] = useState < string > ("");
  const [priceIndia, setPriceIndia] = useState < number > (0);
  const [priceInter, setPriceInter] = useState < number > (0);
  const [courses, setCourses] = useState < Course[] > ([]);
  const [mycourses, setMycourses] = useState < MyCourse[] > ([]);
  const [slots, setSlots] = useState < Slot[] > ([]);
  const [myslots, setMyslots] = useState < MySlots > ({mon:[], tue:[], wed:[], thu:[], fri:[], sat:[], sun:[]});

    function validateEmail(email: string) {
      // eslint-disable-next-line no-control-regex
      const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
      return re.test(String(email).toLowerCase());
    }

    const handleSubmit = () => {
    
      setShowLoading(true);

      if (validateEmail(email) === false) {
        setMessage("Invalid email address");
        setIserror(true);
        setShowLoading(false);
        return;
      }

      if (newPass !== confirmPass)
      {
        setMessage('Passwords do not match.');
        setIserror(true);
        setShowLoading(false);
        return;
      }

      if (!newPass || newPass.length < 8) {
        setMessage("Password must be atleast 8 characters.");
        setIserror(true);
        setShowLoading(false);
        return;
    }
  
      const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 15000,
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
          }
      });

      const addTeacher = (email: string, pass: string, name: string, phone: string, gender: string, city: string, title: string, priceIndia: number, priceInter: number, courses: Array<string>, slots: MySlots) => {
        return new Promise((resolve, reject) => {
          api.post('/teachers/admnAdd', { 'email': email, 'password': pass, 'name': name, 'phone': phone, 'gender': gender, 'city': city, 'title': title, 'priceIndia': priceIndia, 'priceInter': priceInter, 'courses': courses, 'slots': slots }).then(res => {
    
            return resolve(res.data.user.name);
            
          }).catch(err => reject(err));

        });
      }

    let courselist: Array<string> = [];

    for (let i = 0; i < mycourses.length; i++) {
        if(mycourses[i].isChecked)
        courselist.push(mycourses[i]._id);
    }

    addTeacher(email, newPass, name, phone, gender, city, title, priceIndia, priceInter, courselist, myslots)
      .then(data => {
        
        setPass(data+' has been registered.');
        setIspass(true);
        setShowLoading(false);
      })
      .catch(error => {
        if (error.response !== undefined)
            setMessage(error.response.data.message);
        else
            setMessage('Please try again afer sometime.');

        setIserror(true);
        setShowLoading(false);
      });
      
    }
    
    const handleInputChange = (event: any) => {

        const value = event.value;
        let opens: Array < MyCourse > = [...mycourses];
    
        if(event.checked) {
          
          let index = opens.findIndex(el => el._id === value);
          opens[index].isChecked = true; 
    
        } else {
     
          let index = opens.findIndex(el => el._id === value);
          opens[index].isChecked = false;                  
    
        }
    
        setMycourses(opens);
        
    }

    const handleMonChange = (event: any) => {

        const value = event.value;
    
        let monSlots: MySlots = {
          ...myslots
        };
    
        if(event.checked) {
         
          if (!monSlots.mon.includes(value))
            monSlots.mon.push(value);              
         
        } else {
          if (monSlots.mon.includes(value))   
            monSlots.mon = monSlots.mon.filter(item => item !== value)
    
        }
    
        setMyslots(monSlots);
        
    }
    
    const handleTueChange = (event: any) => {
    
      const value = event.value;
    
      let tueSlots: MySlots = {
        ...myslots
    };
    
      if(event.checked) {
       
        if (!tueSlots.tue.includes(value))
        {
            tueSlots.tue.push(value);              
        setMyslots(tueSlots);
        }
    
      } else {
        if (tueSlots.tue.includes(value))   
        {         
          tueSlots.tue = tueSlots.tue.filter(item => item !== value)
        setMyslots(tueSlots);
        }
      }
      
    }
    
    const handleWedChange = (event: any) => {
    
      const value = event.value;
    
      let wedSlots: MySlots = {
        ...myslots
    };
    
      if(event.checked) {
       
        if (!wedSlots.wed.includes(value))
        {
            wedSlots.wed.push(value);              
        setMyslots(wedSlots);
        }
    
      } else {
        if (wedSlots.wed.includes(value))   
        {         
          wedSlots.wed = wedSlots.wed.filter(item => item !== value)
        setMyslots(wedSlots);
        }
      }
      
    }
    
    const handleThuChange = (event: any) => {
    
      const value = event.value;
    
      let thuSlots: MySlots = {
        ...myslots
    };
    
      if(event.checked) {
       
        if (!thuSlots.thu.includes(value))
        {
            thuSlots.thu.push(value);              
        setMyslots(thuSlots);
        }
    
      } else {
        if (thuSlots.thu.includes(value))   
        {         
          thuSlots.thu = thuSlots.thu.filter(item => item !== value)
        setMyslots(thuSlots);
        }
      }
      
    }
    
    const handleFriChange = (event: any) => {
    
      const value = event.value;
    
      let friSlots: MySlots = {
        ...myslots
    };
    
      if(event.checked) {
       
        if (!friSlots.fri.includes(value))
        {
            friSlots.fri.push(value);              
        setMyslots(friSlots);
        }
    
      } else {
        if (friSlots.fri.includes(value))   
        {         
          friSlots.fri = friSlots.fri.filter(item => item !== value)
        setMyslots(friSlots);
        }
      }
      
    }
    
    const handleSatChange = (event: any) => {
    
      const value = event.value;
    
      let satSlots: MySlots = {
        ...myslots
    };
    
      if(event.checked) {
       
        if (!satSlots.sat.includes(value))
        {
            satSlots.sat.push(value);              
        setMyslots(satSlots);
        }
    
      } else {
        if (satSlots.sat.includes(value))   
        {         
          satSlots.sat = satSlots.sat.filter(item => item !== value)
        setMyslots(satSlots);
        }
      }
      
    }
    
    const handleSunChange = (event: any) => {
    
      const value = event.value;
    
      let sunSlots: MySlots = {
        ...myslots
    };
    
      if(event.checked) {
       
        if (!sunSlots.sun.includes(value))
        {
            sunSlots.sun.push(value);              
        setMyslots(sunSlots);
        }
    
      } else {
        if (sunSlots.sun.includes(value))   
        {         
          sunSlots.sun = sunSlots.sun.filter(item => item !== value)
        setMyslots(sunSlots);
        }
      }
      
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const fetchData = async () => {
  
            try {
                const result = await api.get('/courses');
                const slots = await api.get('/slots');
  
                let opens: Array < MyCourse > = [];
                for (let i = 0; i < result.data.length; i++) {
                    opens.push({_id: result.data[i]._id, isChecked: false});
                }
                setMycourses(opens);
                setCourses(result.data);
                setSlots(slots.data);
  
            } catch (error : any) {  
                if (error.response !== undefined) 
                setMessage(error.response.data.message);
                else 
                    setMessage('Something went wrong...');
  
                setIserror(true);
            }
            setShowLoading(false);
        };
  
        fetchData();
  
    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/home"/>
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    onDidDismiss={
                        () => setShowLoading(false)
                    }
                    message={'Please wait...'}/>

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    cssClass="my-custom-alert"
                    header="Oops!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    cssClass="my-custom-alert"
                    header="Success"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-margin-top">
                        <IonCol>
       
                                <IonTitle>Add New Psychologist</IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Email*</IonLabel>
                                            <IonInput type="text"
                                                value={email}
                                                onIonChange={
                                                    (e) => setEmail(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                          lines='none'
                                          button={false}>
                                          <IonLabel position="floating">Set Password*</IonLabel>
                                          <IonInput type="password"
                                              value={newPass}
                                              onIonChange={
                                                  (e) => setNewPass(e.detail.value !)
                                          }></IonInput>
                                      </IonItem>

                                      <IonItem detail={false}
                                          lines='none'
                                          button={false}>
                                          <IonLabel position="floating">Confirm Password*</IonLabel>
                                          <IonInput type="password"
                                              value={confirmPass}
                                              onIonChange={
                                                  (e) => setConfirmPass(e.detail.value !)
                                          }></IonInput>
                                      </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Full Name*</IonLabel>
                                            <IonInput type="text"
                                                value={name}
                                                onIonChange={
                                                    (e) => setName(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Mobile*</IonLabel>
                                            <IonInput type="text"
                                                value={phone}
                                                onIonChange={
                                                    (e) => setPhone(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Gender*</IonLabel>
                                            <IonSelect value={gender}
                                                placeholder="Select Gender"
                                                onIonChange={
                                                    (e) => setGender(e.detail.value)
                                            }>
                                                <IonSelectOption value="Female">Female</IonSelectOption>
                                                <IonSelectOption value="Male">Male</IonSelectOption>
                                                <IonSelectOption value="Other">Other</IonSelectOption>
                                            </IonSelect>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">City*</IonLabel>
                                            <IonInput type="text"
                                                value={city}
                                                onIonChange={
                                                    (e) => setCity(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>       

                                            <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Therapy Price India</IonLabel>
                                            <IonInput type="number"
                                                value={priceIndia}
                                                onIonChange={
                                                    (e) => setPriceIndia(parseInt(e.detail.value !))
                                            }></IonInput>
                                        </IonItem>             
                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Therapy Price International</IonLabel>
                                            <IonInput type="number"
                                                value={priceInter}
                                                onIonChange={
                                                    (e) => setPriceInter(parseInt(e.detail.value !))
                                            }></IonInput>
                                        </IonItem>             
                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Therapy Title</IonLabel>
                                            <IonInput type="text"
                                                value={title}
                                                onIonChange={
                                                    (e) => setTitle(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>                              

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol>
          
                                <IonTitle>Teacher's Services</IonTitle>
              
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">

                                        {
                                        courses.map((course, index) => {
                                            return (
                                                <IonItem key={index}
                                                    detail={false}
                                                    lines='none'
                                                    button={false}
                                                    >
                                                    <IonCheckbox slot="start" value={course._id} checked={(mycourses.find(el => el._id === course._id))?.isChecked} onIonChange={e => handleInputChange(e.detail)} />
                                                   
                                                    <IonLabel> {
                                                        course.name
                                                    } </IonLabel>
                                                </IonItem>
                                            )
                                        })
                                    }

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-top">
                        <IonCol>
          
                                <IonTitle>Teacher's Availability</IonTitle>
              
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardHeader>
                                  <IonCardSubtitle>Monday</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">

                                        {
                                        slots.map((slot, index) => {
                                            return (
                                                <IonItem key={index}
                                                    detail={false}
                                                    lines='none'
                                                    button={false}
                                                    >
                                                    <IonCheckbox slot="start" value={slot._id} checked={myslots?.mon.includes(slot._id)} onIonChange={e => handleMonChange(e.detail)} />
                                                   
                                                    <IonLabel> {
                                                        slot.name
                                                    } </IonLabel>
                                                </IonItem>
                                            )
                                        })
                                    }

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardHeader>
                                  <IonCardSubtitle>Tuesday</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">

                                        {
                                        slots.map((slot, index) => {
                                            return (
                                                <IonItem key={index}
                                                    detail={false}
                                                    lines='none'
                                                    button={false}
                                                    >
                                                    <IonCheckbox slot="start" value={slot._id} checked={myslots?.tue.includes(slot._id)} onIonChange={e => handleTueChange(e.detail)} />
                                                   
                                                    <IonLabel> {
                                                        slot.name
                                                    } </IonLabel>
                                                </IonItem>
                                            )
                                        })
                                    }

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardHeader>
                                  <IonCardSubtitle>Wednesday</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">

                                        {
                                        slots.map((slot, index) => {
                                            return (
                                                <IonItem key={index}
                                                    detail={false}
                                                    lines='none'
                                                    button={false}
                                                    >
                                                    <IonCheckbox slot="start" value={slot._id} checked={myslots?.wed.includes(slot._id)} onIonChange={e => handleWedChange(e.detail)} />
                                                   
                                                    <IonLabel> {
                                                        slot.name
                                                    } </IonLabel>
                                                </IonItem>
                                            )
                                        })
                                    }

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardHeader>
                                  <IonCardSubtitle>Thursday</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">

                                        {
                                        slots.map((slot, index) => {
                                            return (
                                                <IonItem key={index}
                                                    detail={false}
                                                    lines='none'
                                                    button={false}
                                                    >
                                                    <IonCheckbox slot="start" value={slot._id} checked={myslots?.thu.includes(slot._id)} onIonChange={e => handleThuChange(e.detail)} />
                                                   
                                                    <IonLabel> {
                                                        slot.name
                                                    } </IonLabel>
                                                </IonItem>
                                            )
                                        })
                                    }

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardHeader>
                                  <IonCardSubtitle>Friday</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">

                                        {
                                        slots.map((slot, index) => {
                                            return (
                                                <IonItem key={index}
                                                    detail={false}
                                                    lines='none'
                                                    button={false}
                                                    >
                                                    <IonCheckbox slot="start" value={slot._id} checked={myslots?.fri.includes(slot._id)} onIonChange={e => handleFriChange(e.detail)} />
                                                   
                                                    <IonLabel> {
                                                        slot.name
                                                    } </IonLabel>
                                                </IonItem>
                                            )
                                        })
                                    }

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardHeader>
                                  <IonCardSubtitle>Saturday</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">

                                        {
                                        slots.map((slot, index) => {
                                            return (
                                                <IonItem key={index}
                                                    detail={false}
                                                    lines='none'
                                                    button={false}
                                                    >
                                                    <IonCheckbox slot="start" value={slot._id} checked={myslots?.sat.includes(slot._id)} onIonChange={e => handleSatChange(e.detail)} />
                                                   
                                                    <IonLabel> {
                                                        slot.name
                                                    } </IonLabel>
                                                </IonItem>
                                            )
                                        })
                                    }

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardHeader>
                                  <IonCardSubtitle>Sunday</IonCardSubtitle>
                                </IonCardHeader>
                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">

                                        {
                                        slots.map((slot, index) => {
                                            return (
                                                <IonItem key={index}
                                                    detail={false}
                                                    lines='none'
                                                    button={false}
                                                    >
                                                    <IonCheckbox slot="start" value={slot._id} checked={myslots?.sun.includes(slot._id)} onIonChange={e => handleSunChange(e.detail)} />
                                                   
                                                    <IonLabel> {
                                                        slot.name
                                                    } </IonLabel>
                                                </IonItem>
                                            )
                                        })
                                    }

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow className="ion-margin-top">
                        <IonCol sizeXl="3" sizeLg="6" sizeMd="6" sizeSm="9" size="12" className="ion-padding-horizontal">
                            <IonButton onClick={handleSubmit}
                                expand="block">Add Teacher</IonButton>

                        </IonCol>
                    </IonRow>

                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default AddStudent;
