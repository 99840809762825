import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import axios from 'axios';
import React, {useState} from 'react';
import {useAuth} from '../../components/AuthContext';

const Notify: React.FC = () => {
    const {authInfo} = useAuth()!;
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [showAlert1, setShowAlert1] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
   
    const [note, setNote] = useState < string > ("");
    const [title, setTitle] = useState < string > ("");


    const handleSubmit = () => {
    
      setShowLoading(true);
  
      const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 15000,
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
          }
      });

      const send = (title: string, note: string) => {
        return new Promise((resolve, reject) => {
          api.post('/users/notification', { 'title': title, 'note': note }).then(res => {
    
            return resolve(true);
            
          }).catch(err => reject(err));

        });
      }

  
    send(title, note)
      .then(data => {
        
        setPass('Notification sent to all users!');
        setIspass(true);
        setShowLoading(false);
      })
      .catch(error => {
        if (error.response !== undefined)
            setMessage(error.response.data.message);
        else
            setMessage('Please try again afer sometime.');

        setIserror(true);
        setShowLoading(false);
      });
      
    }

    const handleSubmit1 = () => {
    
        setShowLoading(true);
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: 15000,
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const send = () => {
          return new Promise((resolve, reject) => {
            api.get('/users/report').then(res => {
      
              return resolve(true);
              
            }).catch(err => reject(err));
  
          });
        }
  
    
      send()
        .then(data => {
          
          setPass('Report sent to all users!');
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Please try again afer sometime.');
  
          setIserror(true);
          setShowLoading(false);
        });
        
      }
    

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/home"/>
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    onDidDismiss={
                        () => setShowLoading(false)
                    }
                    message={'Please wait...'}/>

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    cssClass="my-custom-alert"
                    header="Oops!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    cssClass="my-custom-alert"
                    header="Success"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass='my-custom-alert'
          header={'Confirm!'}
          message={`Are you sure you want to send this notification to all Altumy users?`}
          buttons={[
            {
              text: 'No',
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Yes',
              handler: () => {
                handleSubmit();
              }
            }
          ]}
        />

        <IonAlert
          isOpen={showAlert1}
          onDidDismiss={() => setShowAlert1(false)}
          cssClass='my-custom-alert'
          header={'Confirm!'}
          message={`Are you sure you want to send feelings report to all Altumy users?`}
          buttons={[
            {
              text: 'No',
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Yes',
              handler: () => {
                handleSubmit1();
              }
            }
          ]}
        />

                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-margin-top">
                        <IonCol>
       
                                <IonTitle>Send Notification</IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Notification Title</IonLabel>
                                            <IonInput type="text"
                                                value={title}
                                                onIonChange={
                                                    (e) => setTitle(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Message</IonLabel>
                                            <IonInput type="text"
                                                value={note}
                                                onIonChange={
                                                    (e) => setNote(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-top">
                        <IonCol sizeXl="3" sizeLg="6" sizeMd="6" sizeSm="9" size="12" className="ion-padding-horizontal">
                            <IonButton onClick={() => setShowAlert(true)}
                                expand="block">Send Notification</IonButton>

                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-top">
                        <IonCol className="ion-padding-horizontal">
                            <p> Few Examples: <br/>
                            How are you feeling today?<br/>
                            Did you forget to pioritize yourself today?<br/>
                            Note down your feelings and thoughts for today.

                            </p>
                        </IonCol>
                    </IonRow>

                      <IonRow className="ion-margin-top">
                        <IonCol sizeXl="3" sizeLg="6" sizeMd="6" sizeSm="9" size="12" className="ion-padding-horizontal">
                            <IonButton onClick={() => setShowAlert1(true)}
                                expand="block">Send Feelings Report</IonButton>

                        </IonCol>
                    </IonRow>

                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default Notify;
