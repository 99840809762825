import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import {alertCircleOutline} from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useAuth} from "../../components/AuthContext";
import { useHistory } from 'react-router-dom';

import '../../theme/style.css';

interface Slot {
    _id: string,
    name: string,
    startTime: string,
    endTime: string,
    duration: number
}

interface Booking {
    _id: string,
    course_name: string,
    date: Date,
    slot: Slot,
    anonymous: boolean,
    price: number,
    attendees: Array < string >
}


const Upcoming: React.FC = () => {
    const history = useHistory();
    const {authInfo} = useAuth()!;
    const [bookings, setBookings] = useState < Booking[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (true);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [tCancel, setTCancel] = useState < string > ("");

    const getBookingType = (anonymous : boolean, attendees : Array < string >) => {
        if (anonymous === true) 
            return 'Anonymous Booking';

        if (attendees.length > 1) 
            return 'Group Booking';

        return 'Individual Booking';
    }

    const formatDate = (today : Date) => {
        let date = new Date(today);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();

        return dd + '/' + mm + '/' + yyyy;
    }

    const startTCancel = (bid : string) => {
        setTCancel(bid);
        setShowAlert(true);
    }

    const handleTCancel = (bid: string) => {
    
        setShowLoading(true);

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const doCancel = ( bid: string ) => {
            return new Promise((resolve, reject) => {
              api.get(`/bookings/cancelbyt/${bid}`).then(res => {
        
                return resolve(res.data.price);
                
              }).catch(err => reject(err));
             
            });
          }

        doCancel(bid)
          .then((amount) => {
                setMessage('Booking cancelled. User has been refunded with '+amount+' credits.');
                setIserror(true);
              setShowLoading(false);
            })
          .catch(error => {
              if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage('Something went wrong...');
            setIserror(true);
            setShowLoading(false);
          });
        
      }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {

            try {
                const result = await api.get(`/bookings/admn/upcoming`);
                setBookings(result.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/home"/>
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    onDidDismiss={
                        () => setShowLoading(false)
                    }
                    message={'Please wait...'}/>

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    cssClass="my-custom-alert"
                    header="Alert"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={showAlert}
                    onDidDismiss={
                        () => setShowAlert(false)
                    }
                    cssClass='my-custom-alert'
                    header='Cancel Confirmation'
                    message='Are you sure you want to cancel this booking?'
                    buttons={
                        [
                            {
                                text: 'Yes',
                                handler: () => {
                                    handleTCancel(tCancel);
                                }
                            }, {
                                text: 'No',
                                role: 'cancel'
                            },
                        ]
                    }/>
                
                <IonGrid>

                    <IonRow className="ion-margin-top">
                        <IonCol>
                             <IonTitle>Upcoming Sessions</IonTitle>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol className="ion-no-padding">
                            {
                            (bookings.length > 0) ? (bookings.map((booking, index) => {
                                return (
                                    <IonCard key={index}>
                                        <IonCardHeader>
                                            <IonCardTitle color="primary" className="ion-text-center">
                                                {
                                                booking.course_name
                                            }</IonCardTitle>

                                        </IonCardHeader>

                                        <IonCardContent className="ion-text-center">

                                            <IonText>Scheduled on {
                                                formatDate(booking.date)
                                            } between<br/> {
                                                booking.slot.name
                                            }</IonText>

                                            <IonItem lines="none">
                                                <IonBadge slot="start" color="tertiary">
                                                    {
                                                    getBookingType(booking.anonymous, booking.attendees)
                                                }
                                                </IonBadge>
                                                <IonBadge slot="end" color="success">Rs. {
                                                    booking.price
                                                }</IonBadge>
                                            </IonItem>
                                       
                                            <IonButton size="small"
                                                onClick={
                                                () => history.push(`/session/${booking._id}`)
                                                }>Details</IonButton>

                                           <IonButton size="small" onClick={
                                                            () => startTCancel(booking._id)
                                                    }>Cancel</IonButton>
                                          
                                        </IonCardContent>
                                    </IonCard>

                                );
                            })) : (
                                <IonCard>

                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"/>
                                            <IonLabel>No upcoming sessions</IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            )
                        } </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>

        </IonPage>
    );
};

export default Upcoming;
