import {
    IonAlert,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../components/AuthContext';

interface ParamTypes {
    bookID: string
}

interface Slot {
    _id: string,
    name: string,
    startTime: string,
    endTime: string,
    duration: number
}

interface Booking {
    _id: string,
    course_name: string,
    date: Date,
    slot: Slot,
    anonymous: boolean,
    price: number,
    attendees: Array < string >
}

const BookSuccess: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {bookID} = useParams < ParamTypes > ();
    const [booking, setBooking] = useState < Booking > ();
    const [showLoading, setShowLoading] = useState < boolean > (true);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");


    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            try {
                const result = await api.get('/bookings/' + bookID);
                setBooking(result.data);


            } catch (error : any) {  
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                else 
                    setMessage('Something went wrong...');

                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchData();

    }, [authInfo, bookID]);


    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>

            

            <IonContent fullscreen={true}>

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    onDidDismiss={
                        () => setShowLoading(false)
                    }
                    message={'Please wait...'}/>
                
                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    cssClass="my-custom-alert"
                    header="Oops!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid>
                    <IonRow className="ion-padding-vertical">
                        <IonCol className="ion-text-center">
                            <img alt="Booking Successful" src="assets/images/success.gif"/>
                        </IonCol>
                    </IonRow>
     
                    <IonRow className="ion-margin-top ion-justify-content-center">
                        <IonCol sizeMd="6" sizeSm="9" size="12">
                            <IonList>
                            <IonItem lines="none" color="primary" className="ion-text-center">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel>
                                                Booking Successful
                                                </IonLabel>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                                <IonItem lines="none" color="light">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel>
                                                    Ref No.
                                                </IonLabel>
                                            </IonCol>
                                            <IonCol className="ion-text-right">
                                                <IonLabel> {bookID} </IonLabel>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                                <IonItem lines="none" color="light">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel>
                                                    Status
                                                </IonLabel>
                                            </IonCol>
                                            <IonCol className="ion-text-right">
                                                <IonLabel>
                                                    Confirmed
                                                </IonLabel>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                                <IonItem lines="none" color="light">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel>
                                                    Scheduled On
                                                </IonLabel>
                                            </IonCol>
                                            <IonCol className="ion-text-right">
                                                <IonLabel> {booking && new Date(booking!.date).getUTCDate()+'/'+ (new Date(booking!.date).getUTCMonth()+1)+'/'+new Date(booking!.date).getUTCFullYear()}</IonLabel>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                                <IonItem lines="none" color="light">
                                    <IonGrid className="ion-no-padding">
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel>
                                                    Timings
                                                </IonLabel>
                                            </IonCol>
                                            <IonCol className="ion-text-right">
                                                <IonLabel> {booking && booking!.slot.name} </IonLabel>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>

                            </IonList>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top ion-justify-content-center">
                        <IonCol className="ion-no-padding" sizeMd="6" sizeSm="9" size="12">
                            <IonButton routerLink='/book' routerDirection="forward" expand="block">
                                Book Another</IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top ion-justify-content-center">
                        <IonCol className="ion-no-padding" sizeMd="6" sizeSm="9" size="12">
                            <IonButton routerLink='/usession' routerDirection="forward" expand="block">
                                Upcoming Bookings</IonButton>
                        </IonCol>
                    </IonRow>


                </IonGrid>
            </IonContent>

        </IonPage>
    );
}

export default BookSuccess;
