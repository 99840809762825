import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useAuth} from "../../components/AuthContext";
import { useParams } from 'react-router-dom';
import { alertCircleOutline } from 'ionicons/icons';

interface Teacher {
    _id: string,
    user_id: string,
    name: string,
    title: string,
    photo: string,
    priceIndia: number,
    priceInter: number
}

interface ParamTypes {
    courseID: string
}

const BookTherapist: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {courseID} = useParams < ParamTypes > ();
    const [teachers, setTeachers] = useState < Teacher[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (true);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {

            try {
                const result = await api.get('/teachers/course/'+courseID);
                setTeachers(result.data);
                setTimeout(() => setShowLoading(false), 400);

            } catch (error: any) {  
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                else 
                    setMessage('Something went wrong...');

                setIserror(true);
                setShowLoading(false);
            }

        };

        fetchData();

    }, [authInfo.token, courseID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/book" />
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    onDidDismiss={
                        () => setShowLoading(false)
                    }
                    message={'Please wait...'}/>

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    cssClass="my-custom-alert"
                    header="Oops!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid>

                    <IonRow className="ion-margin-top">
                        <IonCol>
                             <IonTitle>Select a Therapist</IonTitle>
                        </IonCol>
                    </IonRow>

                    {
                    (teachers.length > 0) ? (teachers.map((teacher) => {
                        return (
                            <IonRow key={teacher._id} >
                                <IonCol className="ion-no-padding">
                                    <IonCard routerLink={ `/bookTherapist/${courseID}/${teacher.user_id}`}
                                        routerDirection="forward">
                                            <IonCardHeader className="ion-text-center border-bottom less-padding">
                                                <IonCardSubtitle color="primary">{teacher.title}</IonCardSubtitle>
                                            </IonCardHeader>
                                           
                                                <IonCardContent className="ion-no-padding" >
                                                    <IonGrid>
                                                        <IonRow>
                                                            <IonCol size="3">
                                                                <div className="vertical-container">
                                                                    <div className="vertical-center"> 
                                                                {teacher.photo && (teacher.photo !== "") ?
                                                                    (<img src={`${process.env.REACT_APP_IMAGE_URL}/${teacher.photo}`} alt="Profile Pic" width="70" />)
                                                                    : (<img src={`assets/images/avatar.png`} alt="Profile Pic" width="70" />)
                                                                }

                                                                    </div>
                                                                </div>

                                                            </IonCol>
                                                            <IonCol size="9" className="ion-text-center">
                                                            <div className="vertical-container">
                                                                    <div className="vertical-center"> 
                                                                        <p className="preview-text">{teacher.name}<br/>
                                                                        
                                                                        <IonBadge color="primary" className='ion-margin-top'>
                                                                            INR {teacher.priceIndia}
                                                                            </IonBadge>
                                                                        </p>
                                                                        </div>
                                                                </div>

                                                            
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonGrid>
                                                </IonCardContent>        
                                       
                                    </IonCard>
                                </IonCol>
                            </IonRow>

                        );
                    })) : (
                        <IonRow className="ion-margin-top">
                            <IonCol>
                                <IonCard>

                                        <IonCardContent>
                                            <IonItem lines="none">
                                                <IonIcon icon={alertCircleOutline}
                                                    slot="start"/>
                                                <IonLabel>No therapists available</IonLabel>

                                            </IonItem>
                                        </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    )
                }  </IonGrid>
            </IonContent>

        </IonPage>
    );
}

export default BookTherapist;
