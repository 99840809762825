import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import {useAuth} from "../../components/AuthContext";

interface Slot {
    _id: string,
    name: string,
    startTime: string,
    endTime: string,
    duration: number
  }
  
  interface Booking {
    _id: string,
    student_id: string,
    teacher_id: string,
    course_name: string,
    status: string,
    date: Date,
    slot: Slot,
    anonymous: boolean,
    price: number,
    settled: number,
    attendees: Array<string>,
    cancelled_by: string,
    refund: number,
    updatedAt: Date
  }

  interface Student {
    _id: string,
    name: string,
    phone: string,
    email: string
  }

interface ParamTypes {
    bookID: string
}

const SessionDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {bookID} = useParams < ParamTypes > ();
    const [showLoading, setShowLoading] = useState < boolean > (true);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [booking, setBooking] = useState < Booking > ();
    const [student, setStudent] = useState < Student > ();
    const [settle, setSettle] = useState < number > (0);

    const getBookingType = (anonymous: boolean, attendees: Array<string>) => {
        if (anonymous === true)
          return 'Anonymous Booking';
    
        if (attendees.length > 1)
          return 'Group Booking';
    
        return 'Individual Booking';
      }
    
      const formatDate = (today: Date) => {
        let date = new Date(today);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
    
        return dd + '/' + mm + '/' + yyyy;
      }

      const handleSettle = () => {
    

        if (settle === 0)
        {
            setMessage("Can't settle for 0");
            setIserror(true);
            return;
        }
        setShowLoading(true);

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const doSettle = ( bid: string, amt: number ) => {
            return new Promise((resolve, reject) => {
              api.get(`/bookings/settle/${bid}/${amt}`).then(res => {
        
                return resolve(res.data.settled);
                
              }).catch(err => reject(err));
             
            });
          }

          doSettle(bookID, settle)
          .then((amount) => {
                setMessage('Booking settled for '+amount+' INR.');
                setIserror(true);
              setShowLoading(false);
            })
          .catch(error => {
              if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage('Something went wrong...');
            setIserror(true);
            setShowLoading(false);
          });
        
      }

    useEffect(() => {

      const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: 15000,
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
          }
      });

      const fetchData = async () => {

          try {

                const result = await api.get('/bookings/' + bookID);
                setBooking(result.data);  
                if (result.data.student_id !== 'Admin')
                {
                    const resultu = await api.get('/users/' + result.data.student_id);
                    setStudent(resultu.data);   
                }
                else
                {
                    const admin =  {
                        _id: 'N/A',
                        name: 'Admin',
                        phone: 'N/A',
                        email: 'N/A'
                      }

                    setStudent(admin);   
                }
                

          } catch (error : any) {  
              if (error.response !== undefined) 
                setMessage(error.response.data.message);
              else 
                setMessage('Something went wrong...');
              setIserror(true);
          }
          setShowLoading(false);
      };

      fetchData();

  }, [authInfo, bookID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/tabs/home"/>
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    onDidDismiss={
                        () => setShowLoading(false)
                    }
                    message={'Please wait...'}/>

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    cssClass="my-custom-alert"
                    header="Alert"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-margin-top">
                        <IonCol>
       
                                <IonTitle>Session Details</IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">

                                    <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel>Session Status: </IonLabel>
                                           <IonText>{booking?.status}</IonText>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel>Course Name: </IonLabel>
                                           <IonText>{booking?.course_name}</IonText>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel>Session Date: </IonLabel>
                                            <IonText>{(booking) && formatDate(booking.date)}</IonText>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel>Session Timings: </IonLabel>
                                            <IonText>{booking?.slot.name}</IonText>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel>Session Type: </IonLabel>
                                            <IonText> {(booking) && getBookingType(booking.anonymous, booking.attendees)} </IonText>
                                        </IonItem>


                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel>Session Cost: </IonLabel>
                                            <IonText>Rs. {booking?.price}</IonText>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel>Booked By: </IonLabel>
                                            <IonText> {student?.name} </IonText>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel>Assigned To: </IonLabel>
                                            <IonText><Link to={`/teacher/${booking?.teacher_id}`}>Teacher Details</Link></IonText>
                                        </IonItem>

                                        {booking && booking.attendees.map((att, i) => {
                                    return (
                                        <IonItem key={i} detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel>User #{i+1}: </IonLabel>
                                            <IonText><Link to={`/client/${att}`}>User Details</Link></IonText>
                                        </IonItem>
                                    )}
                                        )
                                    }

                                    {booking && booking.status === 'Cancelled' &&
                                        <>
                                          <IonItem detail={false}
                                                lines='none'
                                                button={false}>
                                                <IonLabel>Cancelled By: </IonLabel>
                                                <IonText> {booking.cancelled_by} </IonText>
                                            </IonItem>
                                            <IonItem detail={false}
                                                lines='none'
                                                button={false}>
                                                <IonLabel>Cancelled On: </IonLabel>
                                                <IonText> {formatDate(booking.updatedAt)} </IonText>
                                            </IonItem>
                                            <IonItem detail={false}
                                                lines='none'
                                                button={false}>
                                                <IonLabel>Altumy Credits Credited: </IonLabel>
                                                <IonText> {booking.refund} </IonText>
                                            </IonItem>
                                        
                                        </>
                                        }

                                {booking && (booking.settled !== 0) &&
                                        <>
                                         
                                            <IonItem detail={false}
                                                lines='none'
                                                button={false}>
                                                <IonLabel>Settled On: </IonLabel>
                                                <IonText> {formatDate(booking.updatedAt)} </IonText>
                                            </IonItem>
                                            <IonItem detail={false}
                                                lines='none'
                                                button={false}>
                                                <IonLabel>Settled Amount: </IonLabel>
                                                <IonText> {booking.settled} </IonText>
                                            </IonItem>
                                        
                                        </>
                                        }
                                         {booking && (booking.status === 'Completed') && (booking.settled === 0) &&
                                        <>
                                        
                                            <IonItem detail={false}
                                                lines='none'
                                                button={false}>
                                                <IonLabel>Settle For INR: </IonLabel>
                                                <IonInput type="number"
                                                value={settle}
                                                onIonChange={
                                                    (e) => setSettle(parseInt(e.detail.value !))
                                            }></IonInput>
                                            </IonItem>
                                            <IonItem detail={false}
                                                lines='none'
                                                button={false}>
                                                    <IonButton onClick={handleSettle}
                                                    expand="block">Settle Booking</IonButton>
                                            </IonItem>
                                        </>
                                        }

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default SessionDetail;
