import React, { useEffect } from 'react';
import { IonApp, IonLoading, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import Login from '../pages/Auth/Login';

import Menu from './Menu';

import Home from '../pages/Home/Home';
import Notify from '../pages/Home/Notify';
import BookSubject from '../pages/Book/BookSubject';
import BookCourse from "../pages/Book/BookCourse";
import BookTeacher from "../pages/Book/BookTeacher";
import BookDate from "../pages/Book/BookDate";
import BookSlot from "../pages/Book/BookSlot";
import BookTherapy from "../pages/Book/BookTherapy";
import BookTherapist from "../pages/Book/BookTherapist";
import BookDated from "../pages/Book/BookDated";
import TherapySlot from "../pages/Book/TherapySlot";
import BookSuccess from "../pages/Book/BookSuccess";
import IssueCredit from "../pages/Credit/IssueCredit";
import Payments from '../pages/Payment/Payments';
import Upcoming from '../pages/Session/Upcoming';
import Cancelled from "../pages/Session/Cancelled";
import Settled from "../pages/Session/Settled";
import Unsettled from "../pages/Session/Unsettled";
import SessionDetail from '../pages/Session/Detail';
import PendingRedeem from '../pages/Payment/PendingRedeem';
import RedeemHistory from "../pages/Payment/RedeemHistory";
import AddStudent from '../pages/User/AddStudent';
import ViewStudent from '../pages/User/ViewStudent';
import StudentDetail from '../pages/User/StudentDetail';
import AddTeacher from '../pages/User/AddTeacher';
import ViewTeacher from '../pages/User/ViewTeacher';
import TeacherDetail from '../pages/User/TeacherDetail';
import ManageCourse from '../pages/Course/ManageCourse';
import CourseDetail from '../pages/Course/CourseDetail';
import OptionDetail from '../pages/Course/OptionDetail';
import ManageSubject from '../pages/Subject/ManageSubject';
import SubjectDetail from '../pages/Subject/SubjectDetail';
import ManageSlot from '../pages/Slot/ManageSlot';
import SlotDetail from '../pages/Slot/SlotDetail';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../theme/variables.css';
import '../theme/style.css';

import { useAuth } from "./AuthContext";

const App: React.FC = () => {

  const { authInfo, initialize } = useAuth()!;

  useEffect(() => {
    !authInfo?.initialized && (async () => await initialize())();
  }, [authInfo, initialize]);

  if (!authInfo || !authInfo.initialized) {
    return (
      <IonApp>
        <IonLoading isOpen={true} message={'Please wait...'} />
      </IonApp>
    );
  } else {
    return (
      <IonApp>

        {authInfo?.loggedIn !== true ? (
          <IonReactRouter>
            <IonRouterOutlet>
              <Switch>
                <Route path="/login" exact={true} component={Login} />
                <Redirect from="/" to="/login" exact={true} />
                <Redirect from="*" to="/login" />
              </Switch>
            </IonRouterOutlet>
          </IonReactRouter>
        ) : (
            <IonReactRouter>
              <IonSplitPane contentId="main">
                <Menu />

                <IonRouterOutlet id="main">
                  <Switch>
                    <Route path="/home" exact={true} component={Home} />
                    <Route path="/notify" exact={true} component={Notify} />
                    <Route path="/book" exact={true} component={BookSubject} />
                    <Route path="/bookTherapy/:subID" exact={true} component={BookTherapy} />
                    <Route path="/bookTherapist/:courseID" exact={true} component={BookTherapist} />
                    <Route path="/bookTherapist/:courseID/:therapistID" exact={true} component={BookDated} />
                    <Route path="/therapySlot" exact={true} component={TherapySlot} />
                    <Route path="/book/:subID" exact={true} component={BookCourse} />
                    <Route path="/bookTeacher/:courseID/:option" exact={true} component={BookTeacher} />
                    <Route path="/bookTeacher/:courseID/:option/:teacherID" exact={true} component={BookDate} />
                    <Route path="/bookSlot" exact={true} component={BookSlot} />
                    <Route path="/success/:bookID" exact={true} component={BookSuccess} />
                    <Route path="/icredit" exact={true} component={IssueCredit} />
                    <Route path="/payments" exact={true} component={Payments} />
                    <Route path="/usession" exact={true} component={Upcoming} />
                    <Route path="/cansession" exact={true} component={Cancelled} />
                    <Route path="/setsession" exact={true} component={Settled} />
                    <Route path="/unsession" exact={true} component={Unsettled} />
                    <Route path="/session/:bookID" exact={true} component={SessionDetail} />
                    <Route path="/predeem" exact={true} component={PendingRedeem} />
                    <Route path="/hredeem" exact={true} component={RedeemHistory} />
                    <Route path="/aclient" exact={true} component={AddStudent} />
                    <Route path="/vclient" exact={true} component={ViewStudent} />
                    <Route path="/client/:userID" exact={true} component={StudentDetail} />
                    <Route path="/ateacher" exact={true} component={AddTeacher} />
                    <Route path="/vteacher" exact={true} component={ViewTeacher} />
                    <Route path="/teacher/:userID" exact={true} component={TeacherDetail} />
                    <Route path="/courses" exact={true} component={ManageCourse} />
                    <Route path="/course/:courseID" exact={true} component={CourseDetail} />
                    <Route path="/course/option/:courseID/:option" exact={true} component={OptionDetail} />
                    <Route path="/subjects" exact={true} component={ManageSubject} />
                    <Route path="/subject/:subID" exact={true} component={SubjectDetail} />
                    <Route path="/slots" exact={true} component={ManageSlot} />
                    <Route path="/slot/:slotID" exact={true} component={SlotDetail} />
                    <Redirect from="/" to="/home" exact={true} />
                    <Redirect from="*" to="/home" />
                  </Switch>
                </IonRouterOutlet>

              </IonSplitPane>
            </IonReactRouter>
          )}

      </IonApp>
    );
  }
};

export default App;