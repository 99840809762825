import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import {alertCircleOutline, cash} from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useAuth} from "../../components/AuthContext";
import { Link } from 'react-router-dom';


interface RedeemReq {
    _id: string,
    student_id: string,
    status: string,
    createdAt: Date,
    updatedAt: Date,
    amount: number,
    ac_name: string,
    ac_num: string,
    bank_name: string,
    bank_ifsc: string
  }
  
const PendingRedeem: React.FC = () => {
    const {authInfo} = useAuth()!;
    const [redeems, setRedeems] = useState < RedeemReq[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (true);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [showAlert1, setShowAlert1] = useState < boolean > (false);
    const [tCancel, setTCancel] = useState < string > ("");

    const formatDate = (today: Date) => {
        let date = new Date(today);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let hh = date.getHours();
        let ii = date.getMinutes();
        let hrs = String(hh);
        let mins = String(ii);
        if (hh < 10)
          hrs = '0'+String(hh);
        if (ii < 10)
          mins = '0'+String(ii); 
    
        return dd + '/' + mm + ' at ' + hrs + ':' + mins;
      }

      const startCompleted = (bid : string) => {
        setTCancel(bid);
        setShowAlert1(true);
    }

    const handleCompleted = (bid: string) => {
    
        setShowLoading(true);

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const doComplete = ( bid: string ) => {
            return new Promise((resolve, reject) => {
              api.put(`/redeems/${bid}`, {status: 'Completed'}).then(res => {
        
                return resolve(res.data.amount);
                
              }).catch(err => reject(err));
             
            });
          }

        doComplete(bid)
          .then((amount) => {
                setMessage('Redeem Request completed. User has been credited with Rs '+amount+' in bank account.');
                setIserror(true);
              setShowLoading(false);
            })
          .catch(error => {
              if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage('Something went wrong...');
            setIserror(true);
            setShowLoading(false);
          });
        
      }

    const startTCancel = (bid : string) => {
        setTCancel(bid);
        setShowAlert(true);
    }

    const handleTCancel = (bid: string) => {
    
        setShowLoading(true);

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const doCancel = ( bid: string ) => {
            return new Promise((resolve, reject) => {
              api.get(`/redeems/cancelbyt/${bid}`).then(res => {
        
                return resolve(res.data.amount);
                
              }).catch(err => reject(err));
             
            });
          }

        doCancel(bid)
          .then((amount) => {
                setMessage('Redeem Request cancelled. User has been refunded with '+amount+' credits.');
                setIserror(true);
              setShowLoading(false);
            })
          .catch(error => {
              if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage('Something went wrong...');
            setIserror(true);
            setShowLoading(false);
          });
        
      }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {

            try {
                const result = await api.get(`/redeems/pending`);
                setRedeems(result.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/home"/>
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    onDidDismiss={
                        () => setShowLoading(false)
                    }
                    message={'Please wait...'}/>

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    cssClass="my-custom-alert"
                    header="Alert"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={showAlert}
                    onDidDismiss={
                        () => setShowAlert(false)
                    }
                    cssClass='my-custom-alert'
                    header='Cancel Confirmation'
                    message='Are you sure you want to cancel this redeem request?'
                    buttons={
                        [
                            {
                                text: 'Yes',
                                handler: () => {
                                    handleTCancel(tCancel);
                                }
                            }, {
                                text: 'No',
                                role: 'cancel'
                            },
                        ]
                    }/>

                <IonAlert isOpen={showAlert1}
                    onDidDismiss={
                        () => setShowAlert1(false)
                    }
                    cssClass='my-custom-alert'
                    header='Complete Confirmation'
                    message='Are you sure you want to complete this redeem request?'
                    buttons={
                        [
                            {
                                text: 'Yes',
                                handler: () => {
                                    handleCompleted(tCancel);
                                }
                            }, {
                                text: 'No',
                                role: 'cancel'
                            },
                        ]
                    }/>
                
                <IonGrid>

                    <IonRow className="ion-margin-top">
                        <IonCol>
                             <IonTitle>Pending Redeem Requests</IonTitle>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol className="ion-no-padding">
                            {
                            (redeems.length > 0) ? (redeems.map((req, index) => {
                                return (
                                    <IonCard key={index}>
                                        <IonCardHeader>
                                            <IonCardTitle color="primary" className="ion-text-center">
                                                Redeem (Rs. {req.amount}) - { req.status}
                                            </IonCardTitle>

                                        </IonCardHeader>

                                        <IonCardContent className="ion-text-center">
                                        <IonText>
                                            <Link to={`/client/${req.student_id}`}>User Details</Link>
                                        </IonText>
                                        <IonItem button={false}
                                          lines="none"
                                          detail={false}
                                          key = {req._id}>
                                          <IonIcon slot="start"
                                              icon={cash}/>
                                          <IonLabel> A/c: {req.ac_num}
                                          <br/>
                                          Bank: {req.bank_name}
                                          <br/>{
                                              formatDate(req.createdAt)
                                          }</IonLabel>
                                          <IonBadge slot="end" color="warning">Rs. {
                                              req.amount
                                          }</IonBadge>
                                      </IonItem>
                                       
                                            <IonButton size="small"
                                                onClick={
                                                () => startCompleted(req._id)
                                                }>Complete</IonButton>

                                           <IonButton size="small" onClick={
                                                            () => startTCancel(req._id)
                                                    }>Cancel</IonButton>
                                          
                                        </IonCardContent>
                                    </IonCard>

                                );
                            })) : (
                                <IonCard>

                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"/>
                                            <IonLabel>No pending requests</IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            )
                        } </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>

        </IonPage>
    );
};

export default PendingRedeem;
