import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonLoading,
    IonNote,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import axios from 'axios';
import {useAuth} from "../../components/AuthContext";

import {Calendar, utils, DayValue} from '@hassanmojab/react-modern-calendar-datepicker';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';


interface Course {
    _id: string,
    subject_id: string,
    name: string,
    desc: string,
    anonymous: boolean
}

interface Teacher {
    _id: string,
    user_id: string,
    name: string,
    title: string,
    photo: string,
    priceIndia: number,
    priceInter: number
}

interface ParamTypes {
    courseID: string,
    therapistID: string
}

const BookDated: React.FC = () => {
    const history = useHistory();
    const {authInfo} = useAuth()!;
    const {courseID, therapistID} = useParams < ParamTypes > ();
    const [course, setCourse] = useState < Course > ();
    const [teacher, setTeacher] = useState < Teacher > ();
    const [emails, setEmails] = useState < string[] > ([""]);
    const [showLoading, setShowLoading] = useState < boolean > (true);

    const minimumDate = utils('en').getToday();
    
    const maximumDate = {
        year: minimumDate.year,
        month: minimumDate.month + 3,
        day: minimumDate.day
    }
    const [selectedDay, setSelectedDay] = useState < DayValue > (minimumDate);
    const [iserror, setIserror] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");

    function validateEmail(email: string) {
        // eslint-disable-next-line no-control-regex
        const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
        return re.test(String(email).toLowerCase());
      }

      function checkIfArrayIsUnique(myArray: Array<string>) {
        return myArray.length === new Set(myArray).size;
      }

    const handleSubmit = () => {
        setShowLoading(true);
        
        if (!checkIfArrayIsUnique(emails))
        {
            setMessage("Email ID of group members cant be same.");
                setIserror(true);
                setShowLoading(false);
                return;
        }

        for (let i = 0; i < emails.length; i++) {
            if (!emails[i]) {
                setMessage("Invalid email ID of member #" + (i+1));
                setIserror(true);
                setShowLoading(false);
                return;
              }
              if (validateEmail(emails[i]) === false) {
                setMessage("Invalid email ID of member #" + (i+1));
                setIserror(true);
                setShowLoading(false);
                return;
              }
            
        }

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const getSlots = (emails: Array<string>, teacherID: string, selectedDay: DayValue) => {
            return new Promise((resolve, reject) => {
              api.post('/slots/admn/avail', {  'emails': emails, 'selectedDay': selectedDay, 'teacherID': teacherID}).then(res => {
        
                return resolve(res.data.slots);
                
              }).catch(err => reject(err));
             
            });
          }

        getSlots(emails, therapistID, selectedDay)
          .then(slots => {
            setShowLoading(false);
            history.push({
                pathname: '/therapySlot',
                state: { 
                    'slots': slots,
                    'teacher': teacher,
                    'course': course, 
                    'emails': emails, 
                    'selectedDay': selectedDay 
                }});
          })
          .catch(error => {
              if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage('Please try again afer sometime.');
            setIserror(true);
            setShowLoading(false);
          });
       
    }

    const emailsHandler = (email: string, index: number) => {
        let opens: Array < string > = [];
        for (let i = 0; i < emails.length; i++) {
            if (i === index) 
                opens[i] = email;
             else 
                opens[i] = emails[i];
        }
        setEmails(opens);
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchData = async () => {
            try {
                const result = await api.get('/courses/' + courseID);
                setCourse(result.data);

                const teach = await api.get('/teachers/withName/' + therapistID);
                setTeacher(teach.data);
                
                setShowLoading(false);
            } catch (error : any) {  
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                else 
                    setMessage('Something went wrong...');

                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo.token, courseID, therapistID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary"
                            defaultHref={
                                `bookTherapist/${courseID}`
                            }/>
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonLoading isOpen={showLoading}
                    onDidDismiss={
                        () => setShowLoading(false)
                    }
                    message={'Please wait...'}
                />

                <IonAlert
                    isOpen={iserror}
                    onDidDismiss={() => setIserror(false)}
                    cssClass="my-custom-class"
                    header="Something isn't right!"
                    message={message}
                    buttons={["Close"]}
                />

                <IonGrid> 
                    <IonRow className={!isPlatform('ios') ? 'ion-margin-top' : 'ion-margin-top ion-text-center'}>
                        <IonCol>
    
                                    <IonTitle color="primary">
                                        {
                                            course?.name
                                        }
                                    </IonTitle>
                            </IonCol>
                         </IonRow>

                         {teacher && (
                    <IonRow>
                        <IonCol className="ion-no-padding">
                            <IonCard >
                                            <IonCardHeader className="ion-text-center border-bottom less-padding">
                                                <IonCardSubtitle color="primary">{teacher.title}</IonCardSubtitle>
                                            </IonCardHeader>
                                           
                                                <IonCardContent className="ion-no-padding" >
                                                    <IonGrid>
                                                        <IonRow>
                                                            <IonCol size="3">
                                                                <div className="vertical-container">
                                                                    <div className="vertical-center"> 
                                                                {teacher.photo && (teacher.photo !== "") ?
                                                                    (<img src={`${process.env.REACT_APP_IMAGE_URL}/${teacher.photo}`} alt="Profile Pic" width="70" />)
                                                                    : (<img src={`assets/images/avatar.png`} alt="Profile Pic" width="70" />)
                                                                }

                                                                    </div>
                                                                </div>

                                                            </IonCol>
                                                            <IonCol size="9" className="ion-text-center">
                                                            <div className="vertical-container">
                                                                    <div className="vertical-center"> 
                                                                        <p className="preview-text">{teacher.name}<br/>
                                                                        
                                                                        <IonBadge color="primary" className='ion-margin-top'>
                                                                            INR {teacher.priceIndia}
                                                                            </IonBadge>
                                                                        </p>
                                                                        </div>
                                                                </div>

                                                            
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonGrid>
                                                </IonCardContent>        
                                       
                                    </IonCard>
                        </IonCol>
                    </IonRow>
                 )}
                        <IonRow className={!isPlatform('ios') ? 'ion-margin-top' : 'ion-margin-top ion-text-center'}>

                        <IonCol>
                            <IonNote className={!isPlatform('ios') ? 'padding-left-20' : ''}>
                                    Select a Date
                            </IonNote>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-padding">
                        <IonCol>
                            <Calendar value={selectedDay}
                                onChange={setSelectedDay}
                                minimumDate={minimumDate}
                                maximumDate={maximumDate}/>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol className="ion-no-padding" sizeMd="6" sizeSm="9">
                           
                    {
                    emails.map((email, index) => {
                        return (
                            <IonItem key={index}>
                            <IonLabel position="floating"> Email ID of Member #{index+1}</IonLabel>
                            <IonInput
                              type="email"
                              value={email}
                              onIonChange={(e) => emailsHandler(e.detail.value!, index)}
                            >
                            </IonInput>
                          </IonItem>

                        );
                    })
                } 
                    </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol sizeMd="6" sizeSm="9" size="12">
                            <IonButton expand="block"
                                onClick={handleSubmit}>Continue</IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>

        </IonPage>
    );
}

export default BookDated;
