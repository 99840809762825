import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonLoading,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, construct } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router';
import {useAuth} from '../../components/AuthContext';

interface ParamTypes {
    courseID: string
}

interface CourseOptions {
    name: string,
    price: number,
    inter: number,
    additional: number,
    isActive: boolean
}

interface Course {
    _id: string,
    name: string,
    desc: string,
    anonymous: boolean,
    isActive: boolean,
    options: Array < CourseOptions >
}


const CourseDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {courseID} = useParams < ParamTypes > ();
    const [course, setCourse] = useState < Course > ();
    const [active, setActive] = useState < string > ("");
    const [anony, setAnony] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");

    const [text, setText] = useState<string>("");
    const [name, setName] = useState < string > ("");

    const [oname, setOName] = useState < string > ("");
    const [price, setPrice] = useState < number > (0);
    const [inter, setInter] = useState < number > (0);
    const [users, setUsers] = useState < number > (0);


    const handleSection = (section: string) => {
    
      setShowLoading(true);

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

      const toggleCourse = (isActive: string) => {
        return new Promise((resolve, reject) => {

            api.put(`/courses/${courseID}`, { 'isActive': section }).then(res => {
    
                return resolve(res.data.name);
                
              }).catch(err => reject(err));

        });
      }

  
    toggleCourse(section)
      .then(data => {
        if (section === 'enable')
            setActive("Active");
        if (section === 'disable')
            setActive("Inactive");

        setPass(data+' has been updated.');
        setIspass(true);
        setShowLoading(false);
      })
      .catch(error => {
        if (error.response !== undefined)
            setMessage(error.response.data.message);
        else
            setMessage('Please try again afer sometime.');

        setIserror(true);
        setShowLoading(false);
      });
      
    }

    const handleSubmit = () => {
    
        setShowLoading(true);
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: 15000,
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upCourse = (name: string, desc: string, anony: string) => {
          return new Promise((resolve, reject) => {
            api.put('/courses/'+courseID, { 'name': name, 'desc': desc, 'anonymous': anony }).then(res => {
      
              return resolve(res.data.name);
              
            }).catch(err => reject(err));
  
          });
        }
    
    
      upCourse(name, text, anony)
        .then(data => {
  
            setPass(data+' has been updated.');
            setIspass(true);
            setShowLoading(false);
          
        })
        .catch(error => {
            if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Please try again afer sometime.');
          setIserror(true);
          setShowLoading(false);
        });
        
      }

      const handleOption = () => {
    
        setShowLoading(true);
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: 15000,
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upOption = (name: string, price: number, inter: number, additional: number) => {
          return new Promise((resolve, reject) => {
            api.post('/courses/option/add', { 'courseID': courseID, 'options': course?.options.length, 'name': name, 'price': price, 'inter': inter, 'additional': additional }).then(res => {
      
              return resolve(res.data);
              
            }).catch(err => reject(err));
  
          });
        }
    
      upOption(oname, price, inter, users)
        .then(data => {
  
            setPass(course?.name+' has been updated.');
            setIspass(true);
            setShowLoading(false);
          
        })
        .catch(error => {
            if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Please try again afer sometime.');
          setIserror(true);
          setShowLoading(false);
        });
        
      }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });


        const fetchData = async () => {
            try {

                const result = await api.get('/courses/'+courseID);

                setCourse(result.data);
                setName(result.data.name);
                setText(result.data.desc);

                if (result.data.isActive)
                    setActive("Active");
                else
                    setActive("Inactive");

                if (result.data.anonymous)
                    setAnony("Allowed");
                else
                    setAnony("Not Allowed");


                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, courseID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/home"/>
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    onDidDismiss={
                        () => setShowLoading(false)
                    }
                    message={'Please wait...'}/>

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    cssClass="my-custom-alert"
                    header="Oops!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    cssClass="my-custom-alert"
                    header="Success"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-margin-top">
                        <IonCol>
                                <IonTitle>Course Details - {active}</IonTitle>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-padding">
                        <IonCol>
                            <IonSegment onIonChange={e => handleSection( e.detail.value! )} mode="ios" color="secondary">
                                <IonSegmentButton value="enable">
                                    <IonLabel>Make Active</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="disable">
                                    <IonLabel>Make Inactive</IonLabel>
                                </IonSegmentButton>
                            </IonSegment>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Name</IonLabel>
                                            <IonInput type="text"
                                                value={name}
                                                onIonChange={
                                                    (e) => setName(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Description*</IonLabel>
                                            <IonTextarea rows={4} placeholder="Write course description here..." value={text} onIonChange={e => setText(e.detail.value!)}></IonTextarea>

                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Anonymous*</IonLabel>
                                            <IonSelect value={anony}
                                                placeholder="Select One"
                                                onIonChange={
                                                    (e) => setAnony(e.detail.value)
                                            }>
                                                <IonSelectOption value="Allowed">Allowed</IonSelectOption>
                                                <IonSelectOption value="Not Allowed">Not Allowed</IonSelectOption>
       
                                            </IonSelect>
                                        </IonItem>

        

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-top">
                        <IonCol sizeMd="6" sizeSm="9" size="12" className="ion-padding-horizontal">
                            <IonButton onClick={handleSubmit}
                                expand="block">Update Course</IonButton>

                        </IonCol>
                    </IonRow>
                    
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonItemDivider>
                                <IonLabel className="center-label" color="primary">
                                    Courses's Booking Options
                                </IonLabel>
                            </IonItemDivider>
                            {
                            (course && course.options.length > 0) ? (course.options.map((opt, i) => {
                                    return (
                                        <IonItem button={true}
                                            lines="full"
                                            detailIcon={construct}
                                            detail={true}
                                            key = {i}
                                            onClick={
                                                () => history.push(`/course/option/${courseID}/${i}`)
                                            }>
                                            <IonLabel>{opt.name} (Rs. {opt.price})</IonLabel>
                                           
                                        </IonItem>
                                    );
                               
                            })) : (
                                <IonCard>

                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"/>
                                            <IonLabel>No Booking Options</IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            )
                        } </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-top">
                        <IonCol>
                                <IonTitle>Add New Booking Option <br/>(Only for Altumy Learning)</IonTitle>
                        </IonCol>
                    </IonRow>
                   
                    <IonRow>
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Name</IonLabel>
                                            <IonInput type="text"
                                                value={oname}
                                                onIonChange={
                                                    (e) => setOName(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>
                                    
                                     
                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Price (India)</IonLabel>
                                            <IonInput type="number"
                                                value={price}
                                                onIonChange={
                                                    (e) => setPrice(parseInt(e.detail.value !))
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Price (International)</IonLabel>
                                            <IonInput type="number"
                                                value={inter}
                                                onIonChange={
                                                    (e) => setInter(parseInt(e.detail.value !))
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">No of users</IonLabel>
                                            <IonInput type="number"
                                                value={users}
                                                onIonChange={
                                                    (e) => setUsers(parseInt(e.detail.value !))
                                            }></IonInput>
                                        </IonItem>

                         
                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-top">
                        <IonCol sizeMd="6" sizeSm="9" size="12" className="ion-padding-horizontal">
                            <IonButton onClick={handleOption}
                                expand="block">Add Course Option</IonButton>

                        </IonCol>
                    </IonRow>

                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default CourseDetail;
