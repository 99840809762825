import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonLoading,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';

import axios from 'axios';
import {useAuth} from "../../components/AuthContext";


interface Course {
    _id: string,
    subject_id: string,
    name: string,
    desc: string,
    anonymous: boolean
}

interface ParamTypes {
    subID: string
}

const BookTherapy: React.FC = () => {
    const history = useHistory();
    const {subID} = useParams < ParamTypes > ();
    const {authInfo} = useAuth()!;
    const [courses, setCourses] = useState < Course[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (true);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });
        const fetchData = async () => {
            try {
                const result = await api.get('/courses/subject/' + subID);
                setCourses(result.data);
                setShowLoading(false);
            } catch (error : any) {  
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                else 
                    setMessage('Something went wrong...');

                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo.token, subID]);

  
    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/book"/>
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    onDidDismiss={
                        () => setShowLoading(false)
                    }
                    message={'Please wait...'}/>
                
                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    cssClass="my-custom-alert"
                    header="Oops!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid>

                    <IonRow className="ion-margin-top">
                        <IonCol>
                             <IonTitle>Select a Service</IonTitle>
                        </IonCol>
                    </IonRow>

                    {
                    courses.map((course) => {
                        return (
                            <IonRow key={course._id}>
                                <IonCol className="ion-no-padding">
                                    <IonCard onClick={
                                        () =>  history.push('/bookTherapist/' + course._id)
                                    }>
                                        <IonCardHeader>
                                            <IonCardTitle color="primary">{
                                                course.name
                                            }</IonCardTitle>
                                        </IonCardHeader>
                                        <IonCardContent className="ion-text-justify"> <p>{
                                            course.desc
                                        } </p></IonCardContent>
                                       
                                    </IonCard>
                                </IonCol>
                            </IonRow>

                        );
                    })
                } 
                 </IonGrid>
            </IonContent>

        </IonPage>
    );
}

export default BookTherapy;
