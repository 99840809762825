import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonLoading,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonText,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import axios from 'axios';
import { add, alertCircleOutline, remove } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router';
import {useAuth} from '../../components/AuthContext';

interface ParamTypes {
    userID: string
}

interface Slot {
    _id: string,
    name: string,
    startTime: number,
    duration: number
}

interface Booking {
    _id: string,
    course_name: string,
    date: Date,
    slot: Slot,
    anonymous: boolean,
    price: number,
    status: string,
    attendees: Array < string >
}

interface Tran {
    _id: string,
    desc: string,
    createdAt: Date,
    amount_in: number,
    amount_out: number
}

interface Student {
    email: string,
    name: string,
    phone: string,
    country: string,
    gender: string,
    city: string,
    school: string,
    dob: string,
    credits: number,
    isActive: boolean,
    bookings: Array <Booking>,
    trans: Array <Tran>
}

const StudentDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {userID} = useParams < ParamTypes > ();
    const [student, setStudent] = useState < Student > ();
    const [active, setActive] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    
    const getBookingType = (anonymous : boolean, attendees : Array < string >) => {
        if (anonymous === true) 
            return 'Anonymous Booking';

        if (attendees.length > 1) 
            return 'Group Booking';

        return 'Individual Booking';
    }

    const formatDate = (today : Date) => {
        let date = new Date(today);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();

        return dd + '/' + mm + '/' + yyyy;
    }

    const handleSection = (section: string) => {
    
      setShowLoading(true);

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

      const toggleClient = (isActive: string) => {
        return new Promise((resolve, reject) => {
          api.put(`/users/${userID}`, { 'isActive': section }).then(res => {
    
            return resolve(res.data.name);
            
          }).catch(err => reject(err));

        });
      }

  
    toggleClient(section)
      .then(data => {
        if (section === 'enable')
            setActive("Active");
        if (section === 'disable')
            setActive("Inactive");

        setPass(data+' has been updated.');
        setIspass(true);
        setShowLoading(false);
      })
      .catch(error => {
        if (error.response !== undefined)
            setMessage(error.response.data.message);
        else
            setMessage('Please try again afer sometime.');

        setIserror(true);
        setShowLoading(false);
      });
      
    }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });


        const fetchData = async () => {
            try {
                const result = await api.get('/students/admnView/' + userID);
                setStudent(result.data);

                if (result.data.isActive)
                    setActive("Active");
                else
                    setActive("Inactive");

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, userID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/home"/>
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    onDidDismiss={
                        () => setShowLoading(false)
                    }
                    message={'Please wait...'}/>

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    cssClass="my-custom-alert"
                    header="Oops!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    cssClass="my-custom-alert"
                    header="Success"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-margin-top">
                        <IonCol>
                                <IonTitle>Client Details - {active}</IonTitle>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-padding">
                        <IonCol>
                            <IonSegment onIonChange={e => handleSection( e.detail.value! )} mode="ios" color="secondary">
                                <IonSegmentButton value="enable">
                                    <IonLabel>Make Active</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="disable">
                                    <IonLabel>Make Inactive</IonLabel>
                                </IonSegmentButton>
                            </IonSegment>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">

                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           Email:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">
                                                        <IonLabel> {student?.email} </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>

                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           Name:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">
                                                        <IonLabel> {student?.name} </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>


                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           Mobile:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">
                                                        <IonLabel> {student?.phone} </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>

                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           Gender:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">
                                                        <IonLabel> {student?.gender} </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>

                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           Country:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">
                                                        <IonLabel> {student?.country} </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>

                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           City:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">
                                                        <IonLabel> {student?.city} </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>

                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           School:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">
                                                        <IonLabel> {student?.school} </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>

                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           DOB:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">
                                                        <IonLabel>  {student && student.dob && new Date(student.dob).getUTCDate()+'/'+ (new Date(student.dob).getUTCMonth()+1)+'/'+new Date(student.dob).getUTCFullYear()}  </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>

                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           Altumy Credits:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">
                                                        <IonLabel> {student?.credits} </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    
                    <IonRow>
                        <IonCol>
                            <IonItemDivider>
                                <IonLabel className="center-label" color="primary">
                                    Last 5 Altumy Credits Transactions
                                </IonLabel>
                            </IonItemDivider>
                            {
                            (student && student.trans.length > 0) ? (student.trans.map((tran) => {
                                if (tran.amount_out === 0) {
                                    return (
                                        <IonItem button={false}
                                            lines="none"
                                            detail={false}
                                            key = {tran._id}>
                                            <IonIcon slot="start"
                                                icon={add}/>
                                            <IonLabel>{
                                                tran.desc
                                            }<br/> {
                                                formatDate(tran.createdAt)
                                            }</IonLabel>
                                            <IonBadge slot="end" color="success">Rs. {
                                                tran.amount_in
                                            }</IonBadge>
                                        </IonItem>
                                    );
                                } else {
                                    return (
                                        <IonItem button={false}
                                            lines="none"
                                            detail={false}
                                            key = {tran._id}>
                                            <IonIcon slot="start"
                                                icon={remove}/>
                                            <IonLabel>{
                                                tran.desc
                                            }<br/> {
                                              formatDate(tran.createdAt)
                                            }</IonLabel>
                                            <IonBadge slot="end" color="danger">Rs. {
                                                tran.amount_out
                                            }</IonBadge>
                                        </IonItem>
                                    );
                                }
                            })) : (
                                <IonCard>

                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"/>
                                            <IonLabel>No transactions</IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            )
                        } </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol>
                                <IonTitle>Last 5 Bookings</IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-no-padding">
                            {
                            (student && student.bookings.length > 0) ? (student.bookings.map((booking, index) => {
                                return (
                                    <IonCard key={index}>
                                        <IonCardHeader>
                                            <IonCardTitle color="primary" className="ion-text-center">
                                                {
                                                booking.course_name
                                            } - {booking.status}</IonCardTitle>

                                        </IonCardHeader>

                                        <IonCardContent className="ion-text-center">

                                            <IonText>Scheduled on {
                                                formatDate(booking.date)
                                            } between<br/> {
                                                booking.slot.name
                                            }</IonText>

                                            <IonItem lines="none">
                                                <IonBadge slot="start" color="tertiary">
                                                    {
                                                    getBookingType(booking.anonymous, booking.attendees)
                                                }
                                                </IonBadge>
                                                <IonBadge slot="end" color="success">Rs. {
                                                    booking.price
                                                }</IonBadge>
                                            </IonItem>
                                       
                                            <IonButton size="small"
                                                onClick={
                                                () => history.push(`/session/${booking._id}`)
                                                }>Details</IonButton>
                                          
                                        </IonCardContent>
                                    </IonCard>

                                );
                            })) : (
                                <IonCard>

                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"/>
                                            <IonLabel>No Bookings</IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            )
                        } </IonCol>
                    </IonRow>

                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default StudentDetail;
