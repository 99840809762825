import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import axios from 'axios';
import {useAuth} from '../../components/AuthContext';
import {DayValue} from '@hassanmojab/react-modern-calendar-datepicker';
import {chevronForwardCircleOutline, timeOutline} from 'ionicons/icons';

interface CourseOptions {
    name: string,
    price: number,
    inter: number,
    additional: number,
    isActive: boolean
}
interface Course {
    _id: string,
    subject_id: string,
    name: string,
    desc: string,
    anonymous: boolean,
    options: Array < CourseOptions >
}

interface Slot {
    _id: string,
    name: string,
    available: boolean,
    ustdate: Date
}

interface Teacher {
    _id: string,
    user_id: string,
    name: string,
    title: string,
    photo: string,
    priceIndia: number,
    priceInter: number
}

interface LState {
    slots: Array < Slot >,
    teacher: Teacher,
    course: Course,
    option: string,
    emails: Array < string >,
    selectedDay: DayValue

}


const BookSlot: React.FC = () => {
    const {authInfo} = useAuth()!;
    const location = useLocation < LState > ();
    const history = useHistory();
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [slot, setSlot] = useState < Slot > ();
    const [showModal, setShowModal] = useState < boolean > (false);

    const bookingDate = location.state?.selectedDay?.day + '/' + location.state?.selectedDay?.month + '/' + location.state?.selectedDay?.year;
    //const dbDate = location.state?.selectedDay?.year + '-' + location.state?.selectedDay?.month + '-' + location.state?.selectedDay?.day;

    const handleSlot = (slotObj : Slot) => {
        if (slotObj.available) {
            setShowModal(true);
            setSlot(slotObj);
        } else {
            setMessage('Selected slot is not available');
            setIserror(true);
        }
    }


    const handleBooking = () => {

      setShowLoading(true);
 
        const courseID = location.state?.course._id;
        const teacherID = location.state?.teacher.user_id;
        const slotID = slot?._id!;
        const emails = location.state?.emails;
        const bookDay = location.state?.selectedDay;
        const price = location.state?.course.options[parseInt(location.state?.option)].price;
      
        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const doBook = (emails: Array<string>, bookDay: DayValue, courseID: string, teacherID: string, slotID: string, price: number) => {
            return new Promise((resolve, reject) => {
              api.post('/bookings/admn/new', { 'emails': emails, 'bookDay': bookDay, 'courseID': courseID, 'teacherID': teacherID, 'slotID': slotID, 'price': price, 'checkedA': false}).then(res => {
        
                return resolve(res.data.URLpay);
                
              }).catch(err => reject(err));
             
            });
          }

        doBook(emails, bookDay, courseID, teacherID, slotID, price)
          .then((URLpay) => {
            setShowLoading(false);
              history.push(String(URLpay));
             
            })
          .catch(error => {
              if (error.response !== undefined)
              {
                setMessage(error.response.data.message);
                setIserror(true);
                setShowLoading(false);
              }
          });
       
    }

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary"
                            defaultHref={
                                `/bookTeacher/${location.state?.course._id}/${location.state?.option}/${location.state?.teacher._id}`
                            }/>
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    onDidDismiss={
                        () => setShowLoading(false)
                    }
                    message={'Please wait...'}/>

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    cssClass="my-custom-alert"
                    header="Oops!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonModal isOpen={showModal}
                    cssClass='my-custom-modal'
                    swipeToClose={true}
                    onDidDismiss={
                        () => setShowModal(false)
                }>

                    <IonContent>
                        <IonHeader>
                            <IonToolbar>
                                <IonButtons slot="end">
                                    <IonButton onClick={
                                        () => setShowModal(false)
                                    }>
                                        Close
                                    </IonButton>
                                </IonButtons>
                                <IonTitle color="secondary">Confirm Booking</IonTitle>
                            </IonToolbar>
                        </IonHeader>

                        <IonCard className="ion-no-margin">
                            <IonCardContent>

                                <IonItemDivider>
                                    <IonLabel className="center-label" color="secondary">
                                        {
                                        location.state?.course.name
                                    } </IonLabel>
                                </IonItemDivider>

                                <p className="ion-text-justify ion-padding-top">
                                    {
                                    location.state?.course.desc
                                } </p>

                                <IonItemDivider className="ion-margin-top">
                                    <IonLabel className="center-label" color="secondary">
                                        Booking Details
                                    </IonLabel>
                                </IonItemDivider>

                                <IonList>

                                    <IonItem lines="full">
                                        <IonGrid className="ion-no-padding">
                                            <IonRow>
                                                <IonCol>
                                                    <IonLabel>
                                                        Date:
                                                    </IonLabel>
                                                </IonCol>
                                                <IonCol className="ion-text-right">
                                                    <IonLabel> {bookingDate} </IonLabel>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonItem>

                                    <IonItem lines="full">
                                        <IonGrid className="ion-no-padding">
                                            <IonRow>
                                                <IonCol>
                                                    <IonLabel>
                                                        Time:
                                                    </IonLabel>
                                                </IonCol>
                                                <IonCol className="ion-text-right">
                                                    <IonLabel>{
                                                        slot?.name
                                                    } </IonLabel>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonItem>

                                    <IonItem lines="full">
                                          <IonGrid className="ion-no-padding">
                                              <IonRow>
                                                  <IonCol>
                                                      <IonLabel>
                                                          Amount:</IonLabel>
                                                  </IonCol>
                                                  <IonCol className="ion-text-right">
                                                      <IonLabel>
                                                          Rs.{
                                                          location.state?.course.options[parseInt(location.state?.option)].price
                                                      } </IonLabel>
                                                  </IonCol>
                                              </IonRow>
                                          </IonGrid>
                                      </IonItem>

                                      


                                    <IonItem lines="none">
                                        <IonGrid className="ion-no-padding">
                                            <IonRow>
                                                <IonCol>
                                                    <IonLabel>
                                                        Type:
                                                    </IonLabel>
                                                </IonCol>
                                                <IonCol className="ion-text-right">
                                                    <IonLabel>{
                                                        location.state?.course.options[parseInt(location.state?.option)].name
                                                    } </IonLabel>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonItem>

                                    <IonItem lines="none">
                                        <IonGrid className="ion-no-padding">
                                            <IonRow>
                                                <IonCol>
                                                    <IonLabel>
                                                        Teacher:
                                                    </IonLabel>
                                                </IonCol>
                                                <IonCol className="ion-text-right">
                                                    <IonLabel>{
                                                        location.state?.teacher.name
                                                    } </IonLabel>
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </IonItem>

                                    {
                                    location.state?.emails.length > 0 && <IonItemDivider className="ion-margin-top">
                                        <IonLabel className="center-label" color="secondary">
                                            User Details
                                        </IonLabel>
                                    </IonItemDivider>
                                    }

                                    {
                                    location.state?.emails.map((email, index) => {
                                        return (
                                            <IonItem key={index} lines={index+1 === location.state?.emails.length ? 'none' : 'full'}>
                                                <IonGrid className="ion-no-padding">
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonLabel>Member #{
                                                                index + 1
                                                            }:
                                                            </IonLabel>
                                                        </IonCol>
                                                        <IonCol className="ion-text-right">
                                                            <IonLabel>{email}</IonLabel>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonGrid>
                                            </IonItem>

                                        );
                                    })
                                } </IonList>

                            </IonCardContent>
                        </IonCard>

                        <IonButton className="ion-margin" expand="block"
                            onClick={
                                () => handleBooking()
                        }>Confirm and Book</IonButton>

                        <IonButton className="ion-margin" expand="block"
                            onClick={
                                () => setShowModal(false)
                        }>Cancel</IonButton>


                    </IonContent>
                </IonModal>

                <IonGrid>
                    <IonRow className="ion-margin">
                        <IonCol>

                                <IonTitle>
                                    Select a Time Slot
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonList> {
                                location.state?.slots.map((slot, i) => {
                                    return (
                                        <IonItem detail={true}
                                            detailIcon={chevronForwardCircleOutline}
                                            lines='none'
                                            button={
                                                slot.available
                                            }
                                            key={i}
                                            onClick={
                                                () => handleSlot(slot)
                                            }
                                            className='ion-text-center '>
                                            <IonIcon slot="start"
                                                icon={timeOutline}
                                                color={
                                                    slot.available ? 'success' : 'danger'
                                                }/>
                                            <IonLabel color={
                                                slot.available ? 'success' : 'danger'
                                            }>
                                                <h3>{
                                                    slot.name
                                                }</h3>
                                            </IonLabel>
                                        </IonItem>
                                    )
                                })
                            } </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default BookSlot;
