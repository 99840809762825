import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import axios from 'axios';
import { construct, document } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../components/AuthContext';
import {useHistory} from 'react-router-dom';

interface CourseOptions {
    name: string,
    price: number,
    inter: number,
    additional: number,
    duration: number,
    isActive: boolean,
    category: string
}
interface Course {
    _id: string,
    name: string,
    desc: string,
    anonymous: boolean,
    isActive: boolean,
    options: Array < CourseOptions >
}
interface Subject {
    _id: string,
    name: string,
    desc: string,
    isActive: boolean
}

const ManageCourse: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const [courses, setCourses] = useState < Course[] > ([]);
    const [subjects, setSubjects] = useState < Subject[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [subject, setSubject] = useState < string > ("");
    const [text, setText] = useState < string > ("");
    const [name, setName] = useState < string > ("");
    const [anony, setAnony] = useState < string > ("");

    const handleSubmit = () => {
    
        setShowLoading(true);
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: 15000,
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const addCourse = ( subject: string, name: string, desc: string, anony: string) => {
          return new Promise((resolve, reject) => {
            api.post('/courses/admnAdd', { 'subjectID': subject, 'name': name, 'desc': desc, 'anony': anony }).then(res => {
      
              return resolve(res.data.course.name);
              
            }).catch(err => reject(err));
  
          });
        }
  
    
      addCourse(subject, name, text, anony)
        .then(data => {
          
          setPass(data+' has been added.');
          setIspass(true);
          setShowLoading(false);
        })
        .catch(error => {
          if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Please try again afer sometime.');
  
          setIserror(true);
          setShowLoading(false);
        });
        
      }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {

            try {
                const result = await api.get(`/courses`);
                setCourses(result.data);

                const resultSub = await api.get(`/subjects/all`);
                setSubjects(resultSub.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/home"/>
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    onDidDismiss={
                        () => setShowLoading(false)
                    }
                    message={'Please wait...'}/>

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    cssClass="my-custom-alert"
                    header="Oops!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>
                
                 <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    cssClass="my-custom-alert"
                    header="Success"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>


                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-margin-top">
                        <IonCol>
       
                                <IonTitle>Manage Courses</IonTitle>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">

                                    <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                                <IonLabel position="floating">Subject*</IonLabel>
                                                    <IonSelect value={subject}
                                                            placeholder="Select Subject"
                                                            onIonChange={
                                                                (e) => setSubject(e.detail.value)
                                                        }>
                                                        { subjects.map((sub, index) => {
                                                                    return (
                                                                        <IonSelectOption key={index} value={`${sub._id}`}>{sub.name}</IonSelectOption>
                                                                    );
                                                                })
                                                            }
                                            </IonSelect>
                                         </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Name*</IonLabel>
                                            <IonInput type="text"
                                                value={name}
                                                onIonChange={
                                                    (e) => setName(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Description*</IonLabel>
                                            <IonTextarea rows={4} placeholder="Write course description here..." value={text} onIonChange={e => setText(e.detail.value!)}></IonTextarea>

                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Anonymous*</IonLabel>
                                            <IonSelect value={anony}
                                                placeholder="Select One"
                                                onIonChange={
                                                    (e) => setAnony(e.detail.value)
                                            }>
                                                <IonSelectOption value="Allowed">Allowed</IonSelectOption>
                                                <IonSelectOption value="Not Allowed">Not Allowed</IonSelectOption>
       
                                            </IonSelect>
                                        </IonItem>

        

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-top">
                        <IonCol sizeXl="3" sizeLg="6" sizeMd="6" sizeSm="9" size="12" className="ion-padding-horizontal">
                            <IonButton onClick={handleSubmit}
                                expand="block">Add Course</IonButton>

                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin">
                        <IonCol>

                                <IonTitle>
                                    List of Courses
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                  
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonList> {
                                courses.map((course, i) => {
                                    return (
                                        <IonItem detail={true}
                                            detailIcon={construct}
                                            lines='full'
                                            button={true}
                                            key={i}
                                            onClick={
                                                () => history.push(`/course/${course._id}`)
                                            }
                                            className='ion-text-center '>
                                            <IonIcon slot="start"
                                                icon={document}
                                                color={
                                                    course.isActive ? 'success' : 'danger'
                                                }/>
                                            <IonLabel color={
                                                course.isActive ? 'success' : 'danger'
                                            }>
                                                <h3>{
                                                    course.name
                                                }</h3>
                                            </IonLabel>
                                        </IonItem>
                                    )
                                })
                            } </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default ManageCourse;
