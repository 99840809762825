import { IonContent,
  IonHeader,
   IonBackButton,
    IonPage,
    IonTitle,
    IonToolbar, 
    IonLoading, 
    IonAlert, 
    IonGrid, 
    IonRow, 
    IonCol, 
    IonCard, 
    IonCardHeader, 
    IonCardTitle, 
    IonCardContent, 
    IonText, 
    IonItem, 
    IonBadge, 
    IonButtons, 
    IonIcon, 
    IonLabel, 
    IonButton} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useAuth } from "../../components/AuthContext";
import axios from 'axios';
import { alertCircleOutline } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';

interface Slot {
 _id: string,
 name: string,
 startTime: string,
 endTime: string,
 duration: number
}

interface Booking {
 _id: string,
 course_name: string,
 date: Date,
 slot: Slot,
 status: string,
 anonymous: boolean,
 price: number,
 attendees: Array<string>,
 cancelled_by: string,
 refund: number,
 settled: number,
 updatedAt: Date
}

const Settled: React.FC = () => {
 const { authInfo } = useAuth()!;
 const history = useHistory();
 const [bookings, setBookings] = useState<Booking[]>([]);
 const [showLoading, setShowLoading] = useState<boolean>(true);
 const [iserror, setIserror] = useState<boolean>(false);
 const [message, setMessage] = useState<string>("");

 const getBookingType = (anonymous: boolean, attendees: Array<string>) => {
   if (anonymous === true)
     return 'Anonymous Booking';

   if (attendees.length > 1)
     return 'Group Booking';

   return 'Individual Booking';
 }

 const formatDate = (today: Date) => {
   let date = new Date(today);
   let dd = date.getDate();
   let mm = date.getMonth() + 1;
   let yyyy = date.getFullYear();

   return dd + '/' + mm + '/' + yyyy;
 }

 useEffect(() => {

   const api = axios.create({
     baseURL: process.env.REACT_APP_API_URL,
     timeout: 15000,
     headers: {
       'Authorization': 'Bearer ' + authInfo.token
     }
   });

   const fetchUp = async () => {

     try {
       const result = await api.get(`/bookings/admn/settled`);
       setBookings(result.data);
       setShowLoading(false);

     } catch (error : any) {
       if (error.response !== undefined)
         setMessage(error.response.data.message);
       else
         setMessage('Something went wrong...');

       setIserror(true);
       setShowLoading(false);
     }

   };

   fetchUp();


 }, [authInfo]);

 return (
   <IonPage>

     <IonHeader>
       <IonToolbar className="white-shade">
         <IonButtons slot="start">
           <IonBackButton color="secondary" defaultHref="/home" />
         </IonButtons>
         <IonTitle color="secondary">Altumy Admin</IonTitle>
       </IonToolbar>
     </IonHeader>


     <IonContent fullscreen={true} className="grey-shade">

       <IonHeader collapse="condense">
         <IonToolbar>
           <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
         </IonToolbar>
       </IonHeader>
       <IonLoading isOpen={showLoading}
         onDidDismiss={
           () => setShowLoading(false)
         }
         message={'Please wait...'} />

       <IonAlert isOpen={iserror}
         onDidDismiss={
           () => setIserror(false)
         }
         cssClass="my-custom-alert"
         header="Alert"
         message={message}
         buttons={
           ["Close"]
         } />
       <IonGrid>

         <IonRow className="ion-margin-top">
           <IonCol>

               <IonTitle>Settled Sessions</IonTitle>

           </IonCol>
         </IonRow>
         <IonRow>
           <IonCol className="ion-no-padding">
             {
               (bookings.length > 0) ? (bookings.map((booking) => {
                 return (
                   <IonCard key={booking._id}>
                     <IonCardHeader>
                       <IonCardTitle color="primary" className="ion-text-center">
                         {`${booking.course_name} - ${booking.status}`}</IonCardTitle>

                     </IonCardHeader>

                     <IonCardContent className="ion-text-center">

                       <IonText>Scheduled for {
                         formatDate(booking.date)
                       } between<br /> {
                           booking.slot.name
                         }</IonText>

                       <IonItem lines="none">
                         <IonBadge slot="start" color="tertiary">
                           {
                             getBookingType(booking.anonymous, booking.attendees)
                           }
                         </IonBadge>
                        
                         <IonBadge slot="end" color="success">Rs. {
                           booking.price
                         }</IonBadge>
                       </IonItem>

                          <IonText>Settled On {
                            formatDate(booking.updatedAt)
                          }</IonText>

                            <IonItem lines="none">
                              <IonBadge color="primary" slot="end">Settled Amount  : {
                                  booking.settled
                                }
                              </IonBadge>
                         
                           </IonItem>


                           <IonButton size="small"
                                onClick={
                                 () => history.push(`/session/${booking._id}`)
                                }>Details</IonButton>

                     </IonCardContent>
                   </IonCard>

                 );
               })) : (
                   <IonCard>

                     <IonCardContent>
                       <IonItem lines="none">
                         <IonIcon icon={alertCircleOutline}
                           slot="start" />
                         <IonLabel>No Sessions</IonLabel>

                       </IonItem>
                     </IonCardContent>
                   </IonCard>
                 )
             } </IonCol>
         </IonRow>

       </IonGrid>
     </IonContent>

   </IonPage>
 );
};

export default Settled;
