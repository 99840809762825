import {
    IonButtons,
    IonCard,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonMenuButton,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import { book, calendar, card, cash, cashOutline, document, journal, notifications, people, peopleCircle, personAdd, personAddOutline, time, videocam, wallet } from 'ionicons/icons';
import React from 'react';

interface AppPage {
    url: string;
    icon: string;
    title: string;
}
const Home: React.FC = () => {

    let appPages: AppPage[] = [];

    appPages = [
        {
            title: 'Send Notification',
            url: '/notify',
            icon: notifications

        },
        {
            title: 'Book an Appointment',
            url: '/book',
            icon: calendar

        },
        {
            title: 'Issue Credits',
            url: '/icredit',
            icon: cash

        },
        {
            title: 'Payments History',
            url: '/payments',
            icon: cashOutline

        },
        {
            title: 'Upcoming Sessions',
            url: '/usession',
            icon: videocam

        },
        {
            title: 'Completed Sessions (Unsettled)',
            url: '/unsession',
            icon: book

        },
        {
            title: 'Completed Sessions (Settled)',
            url: '/setsession',
            icon: book

        },
        {
            title: 'Cancelled Sessions',
            url: '/cansession',
            icon: book

        },
        {
            title: 'Pending Redeem Requests',
            url: '/predeem',
            icon: card

        },
        {
            title: 'Redeem History',
            url: '/hredeem',
            icon: wallet

        },
        {
            title: 'Add Client',
            url: '/aclient',
            icon: personAdd

        },
        {
            title: 'View Clients',
            url: '/vclient',
            icon: people

        },
        {
            title: 'Add Psychologist',
            url: '/ateacher',
            icon: personAddOutline

        },
        {
            title: 'View Psychologists',
            url: '/vteacher',
            icon: peopleCircle

        },
        {
            title: 'Manage Courses',
            url: '/courses',
            icon: document

        },
        {
            title: 'Manage Subjects',
            url: '/subjects',
            icon: journal

        },
        {
            title: 'Manage Slots',
            url: '/slots',
            icon: time

        },
       
    ];

    return (

        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonMenuButton color="secondary"/>
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonGrid className="ion-no-padding">

                      
                {
                    appPages.map((page, index) => {
                        return (
                            <IonRow key={index} >
                                <IonCol className="ion-no-padding">
                                    <IonCard routerLink={page.url}
                                        routerDirection="forward">
                                       <IonItem className="home-item">
                                        <IonIcon icon={page.icon} slot="start" className="home-icon" />
                                        <IonLabel>{page.title}</IonLabel>
                                       
                                    </IonItem>
                                    </IonCard>
                                </IonCol>
                            </IonRow>

                        );
                    })
                }


                </IonGrid>


            </IonContent>

        </IonPage>
    );
}

export default Home;
