import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonPage,
    IonRow,
    IonTextarea,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import axios from 'axios';
import { construct, journal } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../components/AuthContext';
import {useHistory} from 'react-router-dom';

interface Subject {
    _id: string,
    name: string,
    desc: string,
    isActive: boolean
}

const ManageSubject: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const [subjects, setSubjects] = useState < Subject[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
   
    const [text, setText] = useState<string>("");
    const [name, setName] = useState < string > ("");

    const handleSubmit = () => {
    
      setShowLoading(true);
  
      const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 15000,
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
          }
      });

      const addSubject = ( name: string, desc: string) => {
        return new Promise((resolve, reject) => {
          api.post('/subjects/admnAdd', { 'name': name, 'desc': desc }).then(res => {
    
            return resolve(res.data.subject.name);
            
          }).catch(err => reject(err));

        });
      }

  
    addSubject(name, text)
      .then(data => {
        
        setPass(data+' has been added.');
        setIspass(true);
        setShowLoading(false);
      })
      .catch(error => {
        if (error.response !== undefined)
            setMessage(error.response.data.message);
        else
            setMessage('Please try again afer sometime.');

        setIserror(true);
        setShowLoading(false);
      });
      
    }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {

            try {
                const result = await api.get(`/subjects/all`);
                setSubjects(result.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/home"/>
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    onDidDismiss={
                        () => setShowLoading(false)
                    }
                    message={'Please wait...'}/>

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    cssClass="my-custom-alert"
                    header="Oops!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    cssClass="my-custom-alert"
                    header="Success"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-margin-top">
                        <IonCol>
       
                                <IonTitle>Manage Subjects</IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Name*</IonLabel>
                                            <IonInput type="text"
                                                value={name}
                                                onIonChange={
                                                    (e) => setName(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Description*</IonLabel>
                                            <IonTextarea rows={4} placeholder="Write subject description here..." value={text} onIonChange={e => setText(e.detail.value!)}></IonTextarea>

                                        </IonItem>

        

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-top">
                        <IonCol sizeXl="3" sizeLg="6" sizeMd="6" sizeSm="9" size="12" className="ion-padding-horizontal">
                            <IonButton onClick={handleSubmit}
                                expand="block">Add Subject</IonButton>

                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin">
                        <IonCol>

                                <IonTitle>
                                    List of Subjects
                                </IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonList> {
                                subjects.map((sub, i) => {
                                    return (
                                        <IonItem detail={true}
                                            detailIcon={construct}
                                            lines='full'
                                            button={true}
                                            key={i}
                                            onClick={
                                                () => history.push(`/subject/${sub._id}`)
                                            }
                                            className='ion-text-center '>
                                            <IonIcon slot="start"
                                                icon={journal}
                                                color={
                                                    sub.isActive ? 'success' : 'danger'
                                                }/>
                                            <IonLabel color={
                                                sub.isActive ? 'success' : 'danger'
                                            }>
                                                <h3>{
                                                    sub.name
                                                }</h3>
                                            </IonLabel>
                                        </IonItem>
                                    )
                                })
                            } </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default ManageSubject;
