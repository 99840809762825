import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonLoading,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonTextarea,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline, construct } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router';
import {useAuth} from '../../components/AuthContext';

interface ParamTypes {
    subID: string
}

interface Course {
    _id: string,
    name: string
  }

const SubjectDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {subID} = useParams < ParamTypes > ();
    const [courses, setCourses] = useState < Course[] > ([]);
    const [active, setActive] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");

    const [text, setText] = useState<string>("");
    const [name, setName] = useState < string > ("");

    const handleSection = (section: string) => {
    
      setShowLoading(true);

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

      const toggleSubject = (isActive: string) => {
        return new Promise((resolve, reject) => {

            api.put(`/subjects/${subID}`, { 'isActive': section }).then(res => {
    
                return resolve(res.data.name);
                
              }).catch(err => reject(err));

        });
      }

  
    toggleSubject(section)
      .then(data => {
        if (section === 'enable')
            setActive("Active");
        if (section === 'disable')
            setActive("Inactive");

        setPass(data+' has been updated.');
        setIspass(true);
        setShowLoading(false);
      })
      .catch(error => {
        if (error.response !== undefined)
            setMessage(error.response.data.message);
        else
            setMessage('Please try again afer sometime.');

        setIserror(true);
        setShowLoading(false);
      });
      
    }

    const handleSubmit = () => {
    
        setShowLoading(true);
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: 15000,
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upSub = (name: string, desc: string) => {
          return new Promise((resolve, reject) => {
            api.put('/subjects/'+subID, { 'name': name, 'desc': desc }).then(res => {
      
              return resolve(res.data.name);
              
            }).catch(err => reject(err));
  
          });
        }
    
    
      upSub(name, text)
        .then(data => {
  
            setPass(data+' has been updated.');
            setIspass(true);
            setShowLoading(false);
          
        })
        .catch(error => {
            if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Please try again afer sometime.');
          setIserror(true);
          setShowLoading(false);
        });
        
      }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });


        const fetchData = async () => {
            try {
                const subject = await api.get('/subjects/' + subID);

                const result = await api.get('/courses/subject/' + subID);

                setCourses(result.data);
                setName(subject.data.name);
                setText(subject.data.desc);

                if (subject.data.isActive)
                    setActive("Active");
                else
                    setActive("Inactive");

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, subID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/home"/>
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    onDidDismiss={
                        () => setShowLoading(false)
                    }
                    message={'Please wait...'}/>

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    cssClass="my-custom-alert"
                    header="Oops!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    cssClass="my-custom-alert"
                    header="Success"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-margin-top">
                        <IonCol>
                                <IonTitle>Subject Details - {active}</IonTitle>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-padding">
                        <IonCol>
                            <IonSegment onIonChange={e => handleSection( e.detail.value! )} mode="ios" color="secondary">
                                <IonSegmentButton value="enable">
                                    <IonLabel>Make Active</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="disable">
                                    <IonLabel>Make Inactive</IonLabel>
                                </IonSegmentButton>
                            </IonSegment>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Name</IonLabel>
                                            <IonInput type="text"
                                                value={name}
                                                onIonChange={
                                                    (e) => setName(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Description*</IonLabel>
                                            <IonTextarea rows={4} placeholder="Write subject description here..." value={text} onIonChange={e => setText(e.detail.value!)}></IonTextarea>

                                        </IonItem>

        

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-top">
                        <IonCol sizeXl="3" sizeLg="6" sizeMd="6" sizeSm="9" size="12" className="ion-padding-horizontal">
                            <IonButton onClick={handleSubmit}
                                expand="block">Update Subject</IonButton>

                        </IonCol>
                    </IonRow>
                    
                    <IonRow className="ion-margin-top">
                        <IonCol>
                            <IonItemDivider>
                                <IonLabel className="center-label" color="primary">
                                    Subject's Courses
                                </IonLabel>
                            </IonItemDivider>
                            {
                            (courses && courses.length > 0) ? (courses.map((course) => {
                                    return (
                                        <IonItem button={true}
                                            lines="full"
                                            detailIcon={construct}
                                            detail={true}
                                            key = {course._id}
                                            onClick={
                                                () => history.push(`/course/${course._id}`)
                                            }>
                                            <IonLabel>{course.name}</IonLabel>
                                           
                                        </IonItem>
                                    );
                               
                            })) : (
                                <IonCard>

                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"/>
                                            <IonLabel>No Courses</IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            )
                        } </IonCol>
                    </IonRow>
                   

                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default SubjectDetail;
