import React from 'react';
import axios from 'axios';
import { Plugins } from '@capacitor/core';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 15000
});

const { Storage } = Plugins;

type UserDataInterface = { initialized: boolean; loggedIn: boolean; token: string, user: any };
type MyContextInterface = {
  authInfo: UserDataInterface;
  initialize: () => Promise<boolean>;
  logOut: () => Promise<boolean>;
  logIn: (email: string, password: string) => Promise<boolean>;
};

// create the context
export const AuthContext = React.createContext<MyContextInterface | undefined> (undefined);

// create the context provider, we are using use state to ensure that
// we get reactive values from the context...

export const AuthProvider: React.FC = (props: any) => {
  // the reactive values
  const [authInfo, setAuthInfo] = React.useState<UserDataInterface>();

  const logOut = () => {
    return new Promise((resolve) => {
      Storage.remove({ key: 'AUSER' });
      Storage.remove({ key: 'ATOKEN' });
      setAuthInfo({ initialized: true, loggedIn: false, token: "", user: null });
        return resolve(true);
    });
  };

  const logIn = (email: string, password: string) => {
    return new Promise((resolve, reject) => {
      api.post('/auth/admin/login', { 'email': email, 'password': password}).then(res => {

        let v = {
          initialized: true,
          loggedIn: true,
          token: res.data.token,
          user: res.data.user
        };
        setAuthInfo(v);
        Storage.set({ key: 'AUSER', value: JSON.stringify(v.user)});
        Storage.set({ key: 'ATOKEN', value: v.token});

        return resolve(true);
        
      }).catch(err => reject(err));
     
    });
  };

  const initialize = async () => {
    const resu = await Storage.get({key: 'AUSER'});
    if (resu.value !== null) {
      const { value } = await Storage.get({key: 'ATOKEN'});
      if (value !== null)  {
          setAuthInfo({
            initialized: true,
            loggedIn: true,
            token: value,
            user: JSON.parse(resu.value)
          });
        }
      else {
        setAuthInfo({
          initialized: true,
          loggedIn: false,
          token: "",
          user: null
        });
      }
    }
    else
    {
      setAuthInfo({
        initialized: true,
        loggedIn: false,
        token: "",
        user: null
      });
    }
  };

  let v = {
    authInfo,
    logOut,
    logIn,
    initialize
  };

  return <AuthContext.Provider value={v} {...props} />;
};

export const useAuth = () => React.useContext(AuthContext);
