import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonMenu,
    IonMenuToggle,
    IonNote
} from '@ionic/react';
import React from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import {
    calendarOutline,
    calendarSharp,
    bookOutline,
    bookSharp,
    videocamOutline,
    videocamSharp,
    logOutOutline,
    logOutSharp,
    homeSharp,
    homeOutline,
    walletOutline,
    walletSharp,
    cashOutline,
    cashSharp,
    cardOutline,
    cardSharp,
    personAddOutline,
    personAddSharp,
    peopleOutline,
    peopleSharp,
    personAdd,
    peopleCircleOutline,
    peopleCircleSharp,
    documentOutline,
    documentSharp,
    journalOutline,
    journalSharp,
    timeOutline,
    timeSharp,
    cash,
    notificationsOutline,
    notifications
} from 'ionicons/icons';

import {useAuth} from "./AuthContext";

import '../theme/menu.css';


interface AppPage {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
}

const Menu: React.FC = () => {

    const {logOut} = useAuth()!;
    const location = useLocation();
    const history = useHistory();
    let appPages: AppPage[] = [];

        appPages = [
            {
                title: 'Home',
                url: '/home',
                iosIcon: homeOutline,
                mdIcon: homeSharp

            },
            {
                title: 'Send Notification',
                url: '/notify',
                iosIcon: notificationsOutline,
                mdIcon: notifications

            },
            {
                title: 'Book an Appointment',
                url: '/book',
                iosIcon: calendarOutline,
                mdIcon: calendarSharp

            },
            {
                title: 'Issue Credits',
                url: '/icredit',
                iosIcon: cashOutline,
                mdIcon: cashSharp

            },
            {
                title: 'Payments History',
                url: '/payments',
                iosIcon: cash,
                mdIcon: cash

            },
            {
                title: 'Upcoming Sessions',
                url: '/usession',
                iosIcon: videocamOutline,
                mdIcon: videocamSharp

            },
            {
                title: 'Completed Sessions (Unsettled)',
                url: '/unsession',
                iosIcon: bookOutline,
                mdIcon: bookSharp
    
            },
            {
                title: 'Completed Sessions (Settled)',
                url: '/setsession',
                iosIcon: bookOutline,
                mdIcon: bookSharp
    
            },
            {
                title: 'Cancelled Sessions',
                url: '/cansession',
                iosIcon: bookOutline,
                mdIcon: bookSharp
    
            },
            {
                title: 'Pending Redeem Requests',
                url: '/predeem',
                iosIcon: cardOutline,
                mdIcon: cardSharp

            },
            {
                title: 'Redeem History',
                url: '/hredeem',
                iosIcon: walletOutline,
                mdIcon: walletSharp

            },
            {
                title: 'Add Client',
                url: '/aclient',
                iosIcon: personAddOutline,
                mdIcon: personAddSharp

            },
            {
                title: 'View Clients',
                url: '/vclient',
                iosIcon: peopleOutline,
                mdIcon: peopleSharp

            },
            {
                title: 'Add Psychologist',
                url: '/ateacher',
                iosIcon: personAdd,
                mdIcon: personAdd

            },
            {
                title: 'View Psychologists',
                url: '/vteacher',
                iosIcon: peopleCircleOutline,
                mdIcon: peopleCircleSharp

            },
            {
                title: 'Manage Courses',
                url: '/courses',
                iosIcon: documentOutline,
                mdIcon: documentSharp

            },
            {
                title: 'Manage Subjects',
                url: '/subjects',
                iosIcon: journalOutline,
                mdIcon: journalSharp

            },
            {
                title: 'Manage Slots',
                url: '/slots',
                iosIcon: timeOutline,
                mdIcon: timeSharp

            },
        ];
  

    return (
        <IonMenu contentId="main" type="overlay">

            <IonHeader>

                <IonToolbar color="primary" class="ion-text-center">
                    <IonTitle>Altumy</IonTitle>
                </IonToolbar>


                <IonToolbar color="primary" class="ion-text-center ion-padding-vertical">
                    <IonNote>Welcome Back, Admin</IonNote>
                </IonToolbar>

            </IonHeader>

            <IonContent>

                <IonList id="menu-list">

                    {
                    appPages.map((appPage, index) => {
                        return (
                            <IonMenuToggle key={index}
                                autoHide={false}>
                                <IonItem className={
                                        location.pathname === appPage.url ? 'selected' : ''
                                    }
                                    routerLink={
                                        appPage.url
                                    }
                                    routerDirection="forward"
                                    lines="none"
                                    detail={false}>
                                    <IonIcon slot="start"
                                        ios={
                                            appPage.iosIcon
                                        }
                                        md={
                                            appPage.mdIcon
                                        }/>
                                    <IonLabel>{
                                        appPage.title
                                    }</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        );
                    })
                }

                    <IonMenuToggle autoHide={false}>
                        <IonItem button={true} onClick={
                                async () => {
                                    await logOut();
                                    history.replace("/login");
                                }
                            }
                            lines="none"
                            detail={false}>
                            <IonIcon slot="start"
                                ios={logOutOutline}
                                md={logOutSharp}/>
                            <IonLabel>Logout</IonLabel>
                        </IonItem>
                    </IonMenuToggle>

                </IonList>
            </IonContent>
        </IonMenu>
    );
};

export default Menu;
