import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import {alertCircleOutline} from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useAuth} from "../../components/AuthContext";
import { useHistory } from 'react-router-dom';

import '../../theme/style.css';

interface Payment {
    _id: string,
    booking_id: string,
    txnId: string,
    txnDate: string,
    status: string,
    desc: string,
    txnAmount: string
}


const Payments: React.FC = () => {
    const history = useHistory();
    const {authInfo} = useAuth()!;
    const [payments, setPayments] = useState < Payment[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (true);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");


    const getPaymentStatus = (stat : string) => {
        if (stat === 'TXN_SUCCESS')
            return 'Successful';
        return 'Failed';
    }

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

        const fetchUp = async () => {

            try {
                const result = await api.get(`/payments`);
                setPayments(result.data);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                setIserror(true);
            }
            setShowLoading(false);
        };

        fetchUp();

    }, [authInfo]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/home"/>
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    onDidDismiss={
                        () => setShowLoading(false)
                    }
                    message={'Please wait...'}/>

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    cssClass="my-custom-alert"
                    header="Alert"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

               
                
                <IonGrid>

                    <IonRow className="ion-margin-top">
                        <IonCol>
                             <IonTitle>Payment History</IonTitle>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol className="ion-no-padding">
                            {
                            (payments.length > 0) ? (payments.map((payment, index) => {
                                return (
                                    <IonCard key={index}>

                                        <IonCardContent className="ion-text-center">

                                            <IonText>Payment ID: {payment.txnId} <br/>
                                                    Processed at: {payment.txnDate}</IonText>

                                            <IonItem lines="none">
                                                <IonBadge slot="start" color="warning">
                                                    {
                                                    getPaymentStatus(payment.status)
                                                }
                                                </IonBadge>
                                                <IonBadge slot="end" color="success">Rs. {
                                                    payment.txnAmount
                                                }</IonBadge>
                                            </IonItem>

                                            <IonText>{payment.desc}</IonText>
                                                <br/>

                                            <IonButton size="small" onClick={
                                                            () => history.push(`/session/${payment.booking_id}`)
                                                    }>Booking Details</IonButton>

                                        </IonCardContent>
                                    </IonCard>

                                );
                            })) : (
                                <IonCard>

                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"/>
                                            <IonLabel>No payments</IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            )
                        } </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>

        </IonPage>
    );
};

export default Payments;
