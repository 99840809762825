import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import axios from 'axios';
import React, {useState} from 'react';
import {useAuth} from '../../components/AuthContext';


const IssueCredit: React.FC = () => {
    const {authInfo} = useAuth()!;
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [showAlert, setShowAlert] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
   
    const [email, setEmail] = useState < string > ("");
    const [credits, setCredits] = useState < number > (0);

    function validateEmail(email: string) {
      // eslint-disable-next-line no-control-regex
      const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
      return re.test(String(email).toLowerCase());
    }

    const handleSubmit = () => {
    
      setShowLoading(true);

      if (validateEmail(email) === false) {
        setMessage("Invalid email address");
        setIserror(true);
        setShowLoading(false);
        return;
      }
  
      const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 15000,
        headers: {
            'Authorization': 'Bearer ' + authInfo.token
          }
      });

      const issue = (email: string, credits: number) => {
        return new Promise((resolve, reject) => {
          api.post('/credits/issue', { 'email': email, 'credits': credits }).then(res => {
    
            return resolve(res.data.balance);
            
          }).catch(err => reject(err));

        });
      }

  
    issue(email, credits)
      .then(data => {
        
        setPass('Credits Issued. Users updated balance : '+data);
        setIspass(true);
        setShowLoading(false);
      })
      .catch(error => {
        if (error.response !== undefined)
            setMessage(error.response.data.message);
        else
            setMessage('Please try again afer sometime.');

        setIserror(true);
        setShowLoading(false);
      });
      
    }
    

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/home"/>
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    onDidDismiss={
                        () => setShowLoading(false)
                    }
                    message={'Please wait...'}/>

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    cssClass="my-custom-alert"
                    header="Oops!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    cssClass="my-custom-alert"
                    header="Success"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass='my-custom-alert'
          header={'Confirm!'}
          message={`Are you sure you want to issue ${credits} credits to ${email}?`}
          buttons={[
            {
              text: 'No',
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Yes',
              handler: () => {
                handleSubmit();
              }
            }
          ]}
        />

                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-margin-top">
                        <IonCol>
       
                                <IonTitle>Issue Altumy Credits</IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">


                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">User's Email</IonLabel>
                                            <IonInput type="text"
                                                value={email}
                                                onIonChange={
                                                    (e) => setEmail(e.detail.value !)
                                            }></IonInput>
                                        </IonItem>

                                        <IonItem detail={false}
                                            lines='none'
                                            button={false}>
                                            <IonLabel position="floating">Credits to Issue</IonLabel>
                                            <IonInput type="number"
                                                value={credits}
                                                onIonChange={
                                                    (e) => setCredits(parseInt(e.detail.value !))
                                            }></IonInput>
                                        </IonItem>

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-top">
                        <IonCol sizeXl="3" sizeLg="6" sizeMd="6" sizeSm="9" size="12" className="ion-padding-horizontal">
                            <IonButton onClick={() => setShowAlert(true)}
                                expand="block">Issue Credits</IonButton>

                        </IonCol>
                    </IonRow>

                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default IssueCredit;
