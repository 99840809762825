import {
    IonActionSheet,
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonLoading,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';

import axios from 'axios';
import {useAuth} from "../../components/AuthContext";

import {chevronForwardOutline, closeOutline} from 'ionicons/icons';

interface CourseOptions {
    name: string,
    price: number,
    inter: number,
    additional: number,
    isActive: boolean
}
interface Course {
    _id: string,
    subject_id: string,
    name: string,
    desc: string,
    anonymous: boolean,
    options: Array < CourseOptions >
}

interface ParamTypes {
    subID: string
}

const BookCourse: React.FC = () => {
    const history = useHistory();
    const {subID} = useParams < ParamTypes > ();
    const {authInfo} = useAuth()!;
    const [courses, setCourses] = useState < Course[] > ([]);
    const [showLoading, setShowLoading] = useState < boolean > (true);
    const [showActionSheet, setShowActionSheet] = useState < boolean[] > ([]);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");

    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });
        const fetchData = async () => {
            try {
                const result = await api.get('/courses/subject/' + subID);
                setCourses(result.data);
                let opens: Array < boolean > = [];
                for (let i = 0; i < result.data.length; i++) {
                    opens[i] = false;
                }
                setShowActionSheet(opens);
                setShowLoading(false);
            } catch (error : any) {  
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                else 
                    setMessage('Something went wrong...');

                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo.token, subID]);

    const handleOpen = (id : number) => {
        let opens: Array < boolean > = [];
        for (let i = 0; i < showActionSheet.length; i++) {
            if (i === id) 
                opens[i] = true;
             else 
                opens[i] = false;
        }
        setShowActionSheet(opens);
    }

    const handleClose = () => {
        let opens: Array < boolean > = [];
        for (let i = 0; i < showActionSheet.length; i++) {
            opens[i] = false;
        }
        setShowActionSheet(opens);
    }

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/book"/>
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    onDidDismiss={
                        () => setShowLoading(false)
                    }
                    message={'Please wait...'}/>
                
                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    cssClass="my-custom-alert"
                    header="Oops!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid>

                    <IonRow className="ion-margin-top">
                        <IonCol>
                             <IonTitle>Select a Service</IonTitle>
                        </IonCol>
                    </IonRow>

                    {
                    courses.map((course, index) => {

                        let buttons = [];
                        
                        course.options.forEach((courseOption, i) => {
                            let price: string = String(courseOption.price);
                            if (courseOption.additional !== 0)
                            {
                                let perPerson = Math.round(courseOption.price / (courseOption.additional + 1));
                                price = perPerson + ' per person';

                            }
                            if (courseOption.isActive)
                            {
                                buttons.push({
                                    text: courseOption.name + ' - Rs.' + price,
                                    icon: !isPlatform('ios') ? chevronForwardOutline : undefined,
                                    handler: () => {
                                    
                                        history.push('/bookTeacher/' + course._id + '/' + i);
                                    }
                                })
                            }
                           
                        });


                        buttons.push({
                            text: 'Cancel',
                            icon: !isPlatform('ios') ? closeOutline : undefined,
                            role: 'cancel'
                        });

                        return (
                            <IonRow key={course._id}>
                                <IonCol className="ion-no-padding">
                                    <IonCard onClick={
                                        () => handleOpen(index)
                                    }>
                                        <IonCardHeader>
                                            <IonCardTitle color="primary">{
                                                course.name
                                            }</IonCardTitle>
                                        </IonCardHeader>
                                        <IonCardContent className="ion-text-justify"> <p>{
                                            course.desc
                                        } </p></IonCardContent>
                                        <IonActionSheet isOpen={
                                                showActionSheet[index]
                                            }
                                            onDidDismiss={
                                                () => handleClose()
                                            }
                                            cssClass='my-custom-class'
                                            header={
                                                course.name + ' - Select Booking Type'
                                            }
                                            buttons={buttons}></IonActionSheet>

                                    </IonCard>
                                </IonCol>
                            </IonRow>

                        );
                    })
                } </IonGrid>
            </IonContent>

        </IonPage>
    );
}

export default BookCourse;
