import {
    IonAlert,
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonLoading,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonText,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline } from 'ionicons/icons';
import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router';
import {useAuth} from '../../components/AuthContext';

interface ParamTypes {
    userID: string
}

interface Slot {
    _id: string,
    name: string,
    startTime: string,
    endTime: string,
    duration: number
}

interface Booking {
    _id: string,
    course_name: string,
    date: Date,
    slot: Slot,
    anonymous: boolean,
    price: number,
    status: string,
    attendees: Array < string >
}
interface Course {
    _id: string,
    name: string
  }

interface Teacher {
    email: string,
    name: string,
    phone: string,
    gender: string,
    city: string,
    title: string,
    priceIndia: number,
    priceInter: number,
    isActive: boolean,
    bookings: Array <Booking>,
    courses: Array <string>
}

const TeacherDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const history = useHistory();
    const {userID} = useParams < ParamTypes > ();
    const [teacher, setTeacher] = useState < Teacher > ();
    const [courses, setCourses] = useState < Course[] > ([]);
    const [active, setActive] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    const [priceInter, setPriceInter] = useState < number > (0);
    const [priceIndia, setPriceIndia] = useState < number > (0);
    const [title, setTitle] = useState < string> ("");
    
    const getBookingType = (anonymous : boolean, attendees : Array < string >) => {
        if (anonymous === true) 
            return 'Anonymous Booking';

        if (attendees.length > 1) 
            return 'Group Booking';

        return 'Individual Booking';
    }

    const formatDate = (today : Date) => {
        let date = new Date(today);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();

        return dd + '/' + mm + '/' + yyyy;
    }

    const handleSection = (section: string) => {
    
      setShowLoading(true);

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

      const toggleTeacher = (isActive: string) => {
        return new Promise((resolve, reject) => {
          api.put(`/users/${userID}`, { 'isActive': section }).then(res => {
            api.put(`/teachers/${userID}`, { 'isActive': section }).then(result => {
    
                return resolve(res.data.name);
                
              }).catch(err => reject(err));
          }).catch(err => reject(err));
        });
      }

  
    toggleTeacher(section)
      .then(data => {
        if (section === 'enable')
            setActive("Active");
        if (section === 'disable')
            setActive("Inactive");

        setPass(data+' has been updated.');
        setIspass(true);
        setShowLoading(false);
      })
      .catch(error => {
        if (error.response !== undefined)
            setMessage(error.response.data.message);
        else
            setMessage('Please try again afer sometime.');

        setIserror(true);
        setShowLoading(false);
      });
      
    }

    const handleSubmit = () => {
    
        setShowLoading(true);
    
        const api = axios.create({
          baseURL: process.env.REACT_APP_API_URL,
          timeout: 15000,
          headers: {
              'Authorization': 'Bearer ' + authInfo.token
            }
        });
  
        const upTeacher = (priceInter: number, priceIndia: number, title: string) => {
          return new Promise((resolve, reject) => {
            api.put(`/teachers/${userID}`, { 'priceInter': priceInter, 'priceIndia': priceIndia, 'title': title }).then(res => {
      
              return resolve(res.data);
              
            }).catch(err => reject(err));
  
          });
        }
    
      upTeacher(priceInter, priceIndia, title)
        .then(data => {
  
            setPass(teacher?.name+' has been updated.');
            setIspass(true);
            setShowLoading(false);
          
        })
        .catch(error => {
            if (error.response !== undefined)
              setMessage(error.response.data.message);
          else
              setMessage('Please try again afer sometime.');
          setIserror(true);
          setShowLoading(false);
        });
        
      }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });


        const fetchData = async () => {
            try {
                const result = await api.get('/teachers/admnView/' + userID);
                const courses = await api.get('/courses');
                setTeacher(result.data);
                setPriceIndia(result.data.priceIndia);
                setPriceInter(result.data.priceInter);
                setTitle(result.data.title);
                setCourses(courses.data);

                if (result.data.isActive)
                    setActive("Active");
                else
                    setActive("Inactive");

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, userID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/home"/>
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    onDidDismiss={
                        () => setShowLoading(false)
                    }
                    message={'Please wait...'}/>

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    cssClass="my-custom-alert"
                    header="Oops!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    cssClass="my-custom-alert"
                    header="Success"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-margin-top">
                        <IonCol>
                                <IonTitle>Teacher Details - {active}</IonTitle>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-padding">
                        <IonCol>
                            <IonSegment onIonChange={e => handleSection( e.detail.value! )} mode="ios" color="secondary">
                                <IonSegmentButton value="enable">
                                    <IonLabel>Make Active</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="disable">
                                    <IonLabel>Make Inactive</IonLabel>
                                </IonSegmentButton>
                            </IonSegment>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">

                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           Email:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">
                                                        <IonLabel> {teacher?.email} </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>

                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           Name:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">
                                                        <IonLabel> {teacher?.name} </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>


                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           Mobile:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">
                                                        <IonLabel> {teacher?.phone} </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>

                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           Gender:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">
                                                        <IonLabel> {teacher?.gender} </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>

                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           City:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">
                                                        <IonLabel> {teacher?.city} </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>

                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           Therapy Title:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">

                                                        <IonInput type="text"
                                                            value={title}
                                                            onIonChange={
                                                                (e) => setTitle(e.detail.value !)
                                                        }></IonInput>

                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>

                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           Therapy price India:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">

                                                        <IonInput type="number"
                                                            value={priceIndia}
                                                            onIonChange={
                                                                (e) => setPriceIndia(parseInt(e.detail.value !))
                                                        }></IonInput>

                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>

                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           Therapy price International:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">

                                                        <IonInput type="number"
                                                            value={priceInter}
                                                            onIonChange={
                                                                (e) => setPriceInter(parseInt(e.detail.value !))
                                                        }></IonInput>

                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol sizeMd="6" sizeSm="9" size="12" className="ion-padding-horizontal">
                            <IonButton onClick={handleSubmit}
                                expand="block">Update Therapist </IonButton>

                        </IonCol>
                    </IonRow>
                    
                    <IonRow>
                        <IonCol>
                            <IonItemDivider>
                                <IonLabel className="center-label" color="primary">
                                    Teacher's Services
                                </IonLabel>
                            </IonItemDivider>
                            {
                            (teacher && teacher.courses.length > 0) ? (teacher.courses.map((course) => {
                                    return (
                                        <IonItem button={false}
                                            lines="none"
                                            detail={false}
                                            key = {course}>
                                            <IonLabel>{(courses.find(el => el._id === course))?.name}</IonLabel>
                                           
                                        </IonItem>
                                    );
                               
                            })) : (
                                <IonCard>

                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"/>
                                            <IonLabel>No Courses</IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            )
                        } </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-top">
                        <IonCol>
                                <IonTitle>Last 5 Bookings</IonTitle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-no-padding">
                            {
                            (teacher && teacher.bookings.length > 0) ? (teacher.bookings.map((booking, index) => {
                                return (
                                    <IonCard key={index}>
                                        <IonCardHeader>
                                            <IonCardTitle color="primary" className="ion-text-center">
                                                {
                                                booking.course_name
                                            } - {booking.status}</IonCardTitle>

                                        </IonCardHeader>

                                        <IonCardContent className="ion-text-center">

                                            <IonText>Scheduled on {
                                                formatDate(booking.date)
                                            } between<br/> {
                                                booking.slot.name
                                            }</IonText>

                                            <IonItem lines="none">
                                                <IonBadge slot="start" color="tertiary">
                                                    {
                                                    getBookingType(booking.anonymous, booking.attendees)
                                                }
                                                </IonBadge>
                                                <IonBadge slot="end" color="success">Rs. {
                                                    booking.price
                                                }</IonBadge>
                                            </IonItem>
                                       
                                            <IonButton size="small"
                                                onClick={
                                                () => history.push(`/session/${booking._id}`)
                                                }>Details</IonButton>
                                          
                                        </IonCardContent>
                                    </IonCard>

                                );
                            })) : (
                                <IonCard>

                                    <IonCardContent>
                                        <IonItem lines="none">
                                            <IonIcon icon={alertCircleOutline}
                                                slot="start"/>
                                            <IonLabel>No Bookings</IonLabel>

                                        </IonItem>
                                    </IonCardContent>
                                </IonCard>
                            )
                        } </IonCol>
                    </IonRow>

                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default TeacherDetail;
