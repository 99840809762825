import {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonAlert,
    IonCard,
    IonCardContent
} from '@ionic/react';
import React, {useState} from 'react';

import {useHistory} from "react-router-dom";
import {useAuth} from "../../components/AuthContext";

function validateEmail(email : string) { // eslint-disable-next-line no-control-regex
    const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
    return re.test(String(email).toLowerCase());
}

const Login: React.FC = () => {
    let {logIn} = useAuth()!;
    const history = useHistory();
    const [email, setEmail] = useState < string > ("");
    const [password, setPassword] = useState < string > ("");
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");

    const handleLogin = () => {
        if (!email) {
            setMessage("Thats an invalid email address!");
            setIserror(true);
            return;
        }
        if (validateEmail(email) === false) {
            setMessage("Thats an invalid email address!");
            setIserror(true);
            return;
        }

        if (!password || password.length < 6) {
            setMessage("Thats an invalid password!");
            setIserror(true);
            return;
        }

        logIn(email, password).then(res => {
            history.push("/home");
        }).catch(error => {
            if (error.response !== undefined)
                setMessage(error.response.data.message);
            else
                setMessage('Unable to connect to server');
            setIserror(true);
        });
    };

    return (
        <IonPage>

            <IonContent fullscreen={true}
                className="ion-text-center ion-padding grey-shade">
                <IonAlert isOpen={iserror}
                                onDidDismiss={
                                    () => setIserror(false)
                                }
                                cssClass="my-custom-class"
                                header="Login Error"
                                message={message}
                                buttons={
                                    ["Retry"]
                                }/>
                <IonGrid className="ion-no-padding">
                   <IonRow>
                        <IonCol offsetMd="3" offsetSm="0" offset="0" sizeMd="6" sizeSm="9" size="12">
                            <IonCard>
                                <IonCardContent className="ion-no-padding">
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol className="ion-text-center">
                                                <img alt="Altumy Login" src="assets/images/Altumy.jpeg"/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol>
                                                <IonItem>
                                                    <IonLabel position="floating">
                                                        Email</IonLabel>
                                                    <IonInput type="email"
                                                        value={email}
                                                        onIonChange={
                                                            (e) => setEmail(e.detail.value !)
                                                    }></IonInput>
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol>
                                                <IonItem>
                                                    <IonLabel position="floating">
                                                        Password</IonLabel>
                                                    <IonInput type="password"
                                                        value={password}
                                                        onIonChange={
                                                            (e) => setPassword(e.detail.value !)
                                                    }></IonInput>
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-justify-content-center ion-padding">
                                            <IonCol>
                                                <IonButton expand="block"
                                                    onClick={handleLogin}>Admin Login</IonButton>
                                              
                                            </IonCol>
                                        </IonRow>
                                     </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Login;
