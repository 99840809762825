import {
    IonAlert,
    IonBackButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonPage,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import axios from 'axios';

import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router';
import {useAuth} from '../../components/AuthContext';

interface ParamTypes {
    slotID: string
}

interface Slot {
    _id: string,
    name: string,
    startTime: string,
    endTime: string,
    duration: number
}


const SlotDetail: React.FC = () => {
    const {authInfo} = useAuth()!;
    const {slotID} = useParams < ParamTypes > ();
    const [slot, setSlot] = useState < Slot > ();
    const [active, setActive] = useState < string > ("");
    const [showLoading, setShowLoading] = useState < boolean > (false);
    const [iserror, setIserror] = useState < boolean > (false);
    const [message, setMessage] = useState < string > ("");
    const [ispass, setIspass] = useState < boolean > (false);
    const [pass, setPass] = useState < string > ("");
    

    const handleSection = (section: string) => {
    
      setShowLoading(true);

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });

      const toggleSlot = (isActive: string) => {
        return new Promise((resolve, reject) => {
          api.put(`/slots/${slotID}`, { 'isActive': section }).then(res => {
         
                return resolve(res.data.name);

          }).catch(err => reject(err));
        });
      }

  
    toggleSlot(section)
      .then(data => {
        if (section === 'enable')
            setActive("Active");
        if (section === 'disable')
            setActive("Inactive");

        setPass(data+' has been updated.');
        setIspass(true);
        setShowLoading(false);
      })
      .catch(error => {
        if (error.response !== undefined)
            setMessage(error.response.data.message);
        else
            setMessage('Please try again afer sometime.');

        setIserror(true);
        setShowLoading(false);
      });
      
    }
    
    useEffect(() => {

        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 15000,
            headers: {
                'Authorization': 'Bearer ' + authInfo.token
            }
        });


        const fetchData = async () => {
            try {
                const result = await api.get('/slots/' + slotID);
               
                setSlot(result.data);

                if (result.data.isActive)
                    setActive("Active");
                else
                    setActive("Inactive");

                setShowLoading(false);

            } catch (error : any) {
                if (error.response !== undefined) 
                    setMessage(error.response.data.message);
                 else 
                    setMessage('Something went wrong...');
                
                setIserror(true);
                setShowLoading(false);
            }
        };

        fetchData();

    }, [authInfo, slotID]);

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar className="white-shade">
                    <IonButtons slot="start">
                        <IonBackButton color="secondary" defaultHref="/home"/>
                    </IonButtons>
                    <IonTitle color="secondary">Altumy Admin</IonTitle>
                </IonToolbar>
            </IonHeader>


            <IonContent fullscreen={true} className="grey-shade">

                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" color="secondary">Altumy Admin</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonLoading isOpen={showLoading}
                    onDidDismiss={
                        () => setShowLoading(false)
                    }
                    message={'Please wait...'}/>

                <IonAlert isOpen={iserror}
                    onDidDismiss={
                        () => setIserror(false)
                    }
                    cssClass="my-custom-alert"
                    header="Oops!"
                    message={message}
                    buttons={
                        ["Close"]
                    }/>

                <IonAlert isOpen={ispass}
                    onDidDismiss={
                        () => setIspass(false)
                    }
                    cssClass="my-custom-alert"
                    header="Success"
                    message={pass}
                    buttons={
                        ["Close"]
                    }/>

                <IonGrid className="ion-no-padding">

                    <IonRow className="ion-margin-top">
                        <IonCol>
                                <IonTitle>Slot Details - {active}</IonTitle>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-padding">
                        <IonCol>
                            <IonSegment onIonChange={e => handleSection( e.detail.value! )} mode="ios" color="secondary">
                                <IonSegmentButton value="enable">
                                    <IonLabel>Make Active</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="disable">
                                    <IonLabel>Make Inactive</IonLabel>
                                </IonSegmentButton>
                            </IonSegment>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonCard>

                                <IonCardContent className="ion-no-padding">
                                    <IonList className="ion-no-padding">

                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           Name:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">
                                                        <IonLabel> {slot?.name} </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>

                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           Start Time:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">
                                                        <IonLabel> {slot?.startTime} </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>


                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           End Time:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">
                                                        <IonLabel> {slot?.endTime} </IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>

                                        <IonItem lines="full">
                                            <IonGrid className="ion-no-padding">
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>
                                                           Duration:
                                                        </IonLabel>
                                                    </IonCol>
                                                    <IonCol className="ion-text-right">
                                                        <IonLabel> {slot?.duration} minutes</IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>

                                    </IonList>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>


                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default SlotDetail;
